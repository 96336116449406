import { arrayToMap } from 'common/dist/utils/arrayToMap';
import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as Api from '../../core/api';

export const fetchHistory = createAction(
  'fetch job group history',
  (offset, limit, search) => ({ offset, limit, search })
);

export const fetchHistorySuccess = createAction(
  'fetch job group history - success',
  (data) => data
);

export const fetchHistoryError = createAction(
  'fetch job group history - error',
  (error) => error
);

export const fetchJobGroupDetailsHistory = createAction(
  'fetch job group details history',
  (jobGroupCode) => ({
    jobGroupCode,
  })
);

export const fetchJobGroupDetailsHistorySuccess = createAction(
  'fetch job group details history - success',
  (jobGroupCode, data) => ({ jobGroupCode, data })
);

export const fetchJobGroupDetailsHistoryFailure = createAction(
  'fetch job group details history - failure',
  (jobGroupCode, error) => ({ jobGroupCode, error })
);

export const reducer = {
  [fetchHistory]: (state) => ({
    ...state,
    jobGroupHistory: {
      ...state.jobGroupHistory,
      loading: true,
    },
  }),
  [fetchHistorySuccess](state, data) {
    const { jobGroups, totalCount } = data;
    return {
      ...state,
      jobGroupHistory: {
        ...state.jobGroupHistory,
        loading: false,
        loaded: true,
        error: '',
        codes: jobGroups.map((jg) => jg.code),
        jobGroups: arrayToMap(jobGroups, 'code'),
        totalCount,
      },
    };
  },
  [fetchHistoryError]: (state, error) => ({
    ...state,
    jobGroupHistory: {
      ...state.jobGroupHistory,
      loading: false,
      loaded: true,
      error: error || 'History could not be loaded.', // TODO Use intl-id
      codes: [],
      jobGroups: {},
      totalCount: 0,
    },
  }),
  [fetchJobGroupDetailsHistory]: (state, { jobGroupCode }) => ({
    ...state,
    jobGroupHistory: {
      ...state.jobGroupHistory,
      loading: true,
    },
  }),
  [fetchJobGroupDetailsHistorySuccess]: (state, { jobGroupCode, data }) => ({
    ...state,
    jobGroupHistory: {
      ...state.jobGroupHistory,
      loading: false,
      loaded: true,
      error: undefined,
      codes: state.jobGroupHistory.codes.includes(jobGroupCode)
        ? state.jobGroupHistory.codes
        : [...state.jobGroupHistory.codes, jobGroupCode],
      jobGroups: {
        ...state.jobGroupHistory.jobGroups,
        [jobGroupCode]: data,
      },
    },
  }),
  [fetchJobGroupDetailsHistoryFailure]: (state, { jobGroupCode, error }) => ({
    ...state,
    jobGroupHistory: {
      ...state.jobGroupHistory,
      loading: false,
      loaded: false,
      error,
    },
  }),
};

export function* fetchHistorySaga({ payload: { offset, limit, search } }) {
  const { response, error } = yield call(
    Api.orchestration.fetchHistory,
    offset,
    limit,
    search
  );
  if (response) {
    yield put(fetchHistorySuccess(response));
  } else {
    yield put(fetchHistoryError(error));
  }
}

export function* watchFetchHistory() {
  yield takeEvery(fetchHistory.getType(), fetchHistorySaga);
}

// TODO: fix naming
export function* fetchJobGroupDetailsHistorySaga({
  payload: { jobGroupCode },
}) {
  const { response, error } = yield call(
    Api.orchestration.fetchJobGroupDetailsHistory,
    jobGroupCode
  );
  if (response) {
    yield put(fetchJobGroupDetailsHistorySuccess(jobGroupCode, response));
  } else {
    yield put(fetchJobGroupDetailsHistoryFailure(jobGroupCode, error));
  }
}

export function* watchFetchJobGroupDetailsHistory() {
  yield takeEvery(
    fetchJobGroupDetailsHistory.getType(),
    fetchJobGroupDetailsHistorySaga
  );
}

import { formatDate } from 'common/dist/utils/dates';
import _ from 'lodash';
import React, { FC, useEffect } from 'react';

import { CProps } from './RunningWorkbench.container';
import { useUserSummaries } from '../../../../core/api/users';
import { UserResponse } from '../../../../store/admin/resources/api.types';
import Busy from '../../../atoms/busy/Busy';
import Button from '../../../atoms/button/Button';
import IndicatorEmpty from '../../../molecules/indicator-empty/IndicatorEmpty';
import styles from '../styles.module.scss';

export type ServerListEntry = {
  userId: string;
  appVersionCode: string;
  created: string;
  lastActivity: string;
  ready: boolean;
  pending: boolean;
};
export function flattenUserResponses(
  userResponses: UserResponse[]
): ServerListEntry[] {
  return _.flatMap(userResponses, (user) =>
    Object.values(user.servers).map((s) => ({
      userId: user.name,
      appVersionCode: s.name,
      created: user.created,
      lastActivity: user.last_activity,
      ready: s.ready,
      pending: s.pending,
    }))
  );
}

type Props = {};

type CombinedProps = Props & CProps;

const Loading: FC = () => {
  return (
    <div className={styles.parent}>
      <Busy isBusy />
    </div>
  );
};

const Empty: FC = () => {
  return (
    <IndicatorEmpty
      classNameImage={'resources-no-workbench-pic'}
      headlineId={'no-id'}
      headlineDefault={'There are no running Workbench Servers.'}
    />
  );
};

const RunningWorkbench: FC<CombinedProps> = ({
  error,
  data,
  stopWorkbench,
  stoppingServers,
  loaded,
  loading,
  fetchRunningWorkbench,
}) => {
  useEffect(() => {
    fetchRunningWorkbench();
  }, []);

  const flattened = flattenUserResponses(data);
  const users = useUserSummaries(
    flattened.map((serverListEntry) => serverListEntry.userId)
  );

  if (loading) return <Loading />;
  else if (error)
    return (
      <div className={styles.parent}>
        <span className={'error'}>{JSON.stringify(error)}</span>
      </div>
    );
  else if (!data || flattenUserResponses(data).length === 0) return <Empty />;

  return (
    <div className={styles.parent}>
      <div className={'ct-list'}>
        <div className={'ct-headline ct-row'}>
          <div className={'ct-col ct-col-160px'}>
            <span>First Name</span>
          </div>
          <div className={'ct-col ct-col-160px'}>
            <span>Last Name</span>
          </div>
          <div className={'ct-col ct-col-280px'}>
            <span>User</span>
          </div>
          <div className={'ct-col ct-col-160px'}>
            <span>Started</span>
          </div>
          <div className={'ct-col ct-col-160px'}>
            <span>Last Activity</span>
          </div>
          <div className={'ct-col ct-col-80px'}>
            <span>Ready</span>
          </div>
          <div className={'ct-col ct-col-160px'}></div>
        </div>
        {_.zip(flattened, users).map(([e, user], i) => {
          const userSummary = user?.data;
          return (
            <div key={i} className={'ct-row'}>
              <div className={'ct-col ct-col-160px'}>
                <span>{userSummary?.firstName}</span>
              </div>
              <div className={'ct-col ct-col-160px'}>
                <span>{userSummary?.lastName}</span>
              </div>
              <div className={'ct-col ct-col-280px'}>
                <span>{e.userId}</span>
              </div>
              <div className={'ct-col ct-col-160px'}>
                <span>
                  {formatDate(new Date(e.created), 'dd.MM.yyyy HH:mm:ss')}
                </span>
              </div>
              <div className={'ct-col ct-col-160px'}>
                <span>
                  {formatDate(new Date(e.lastActivity), 'dd.MM.yyyy HH:mm:ss')}
                </span>
              </div>
              <div className={'ct-col ct-col-80px'}>
                <span>{e.ready + ''}</span>
              </div>
              <div className={'ct-col ct-col-160px'}>
                {e.ready &&
                  (stoppingServers.includes(e.userId) ? (
                    <Button
                      color={'orange'}
                      label={'Stopping ...'}
                      disabled
                      className={styles.stopWorkbenchButton}
                    />
                  ) : (
                    <Button
                      color={'orange'}
                      label={'Shutdown'}
                      className={styles.stopWorkbenchButton}
                      onClick={() => {
                        stopWorkbench(e.userId);
                      }}
                    />
                  ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RunningWorkbench;

import { connect, ConnectedProps } from 'react-redux';

import Buckets, { Props } from './Buckets';
import { setActiveUploadWizard } from '../../../../redux/modules/data.cassandra.module';
import {
  fetchBuckets,
  fetchS3Permissions,
} from '../../../../redux/modules/data.s3.module';
import { RootState } from '../../../../store/store';

export function mapStateToProps(state: RootState, { dataSourceCode }: Props) {
  const permissions = state.data.s3[dataSourceCode]?.permissions || {};
  return {
    permissions,
  };
}

export const mapDispatchToProps = {
  fetchBuckets,
  fetchS3Permissions,
  setActiveUploadWizard,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(Buckets);

import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import notificationsMsgs from 'common/dist/messages/notifications';
import { Augur } from 'common/dist/types/augur';
import { AugurSettings } from 'common/dist/types/augurSettings';
import {
  PostAugurRequestBody,
  PutAugurRequestBody,
  PutAugurSettingsRequestBody,
} from 'common/dist/types/requestBodies/augurs';
import { NamesResponseBody } from 'common/dist/types/responseBodies/base';
import { ResourceNames } from 'common/dist/types/utils';
import qs from 'qs';

import {
  apiRequest,
  CompletedApiRequest,
  deleteApiRequest,
  fetchQueryFn,
  postApiRequest,
  putApiRequest,
} from './_tools';
import { sendNotification } from '../../redux/modules/notifications.module';
import { useAppDispatch } from '../../store/store';
import { error as errorType } from '../notifications';

export const augurKeys = {
  all: () => ['augurs'] as const,
  augur: (augurCode: string) => [...augurKeys.all(), augurCode] as const,
  augurs: (habitatCode: string, archived?: boolean) =>
    [...augurKeys.all(), habitatCode, archived] as const,
  add: () => [...augurKeys.all(), 'add'] as const,
  update: (augurCode: string) =>
    [...augurKeys.augur(augurCode), 'update'] as const,
  updateWithAugurCode: () => [...augurKeys.all(), 'update'] as const,
  delete: (augurCode: string) =>
    [...augurKeys.augur(augurCode), 'delete'] as const,
  names: (habitatCode: string) =>
    [...augurKeys.all(), 'names', habitatCode] as const,
};

export const augurSettingsKeys = {
  some: (augurCode: string) =>
    [...augurKeys.augur(augurCode), 'settings'] as const,
  settings: (augurCode: string, modelCode: string) =>
    [...augurSettingsKeys.some(augurCode), modelCode] as const,
  update: (augurCode: string) =>
    [...augurSettingsKeys.some(augurCode), 'update'] as const,
  settingsHistory: (augurCode: string, offset: number, limit: number) =>
    [...augurSettingsKeys.some(augurCode), offset, limit] as const,
};

export function getSettings(
  augurCode: string,
  modelCode?: string
): CompletedApiRequest<AugurSettings> {
  const query = qs.stringify({ modelCode }, { addQueryPrefix: true });

  return apiRequest(`/api/augurs/${augurCode}/settings${query}`);
}

export const useSettings = (
  augurCode: string,
  modelCode?: string
): UseQueryResult<AugurSettings> => {
  const key = augurSettingsKeys.settings(augurCode, modelCode);
  return useQuery(key, () =>
    fetchQueryFn(key, () => getSettings(augurCode, modelCode))
  );
};

export function putSettings(
  augurCode: string,
  payload: PutAugurSettingsRequestBody
) {
  return putApiRequest(`/api/augurs/${augurCode}/settings`, payload);
}

export function useUpdateSettings(augurCode: string) {
  const queryClient = useQueryClient();
  const key = augurSettingsKeys.update(augurCode);
  return useMutation(
    key,
    (payload: PutAugurSettingsRequestBody) =>
      fetchQueryFn(key, () => putSettings(augurCode, payload)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: augurSettingsKeys.some(augurCode),
        });
      },
    }
  );
}

export function getSettingsHistory(
  augurCode: string,
  offset?: number,
  limit?: number
): CompletedApiRequest<AugurSettings[]> {
  const query = qs.stringify({ offset, limit }, { addQueryPrefix: true });

  return apiRequest(`/api/augurs/${augurCode}/settings/history${query}`);
}

export const useSettingsHistory = (
  augurCode: string,
  offset?: number,
  limit?: number
): UseQueryResult<AugurSettings[]> => {
  const key = augurSettingsKeys.settingsHistory(augurCode, offset, limit);
  return useQuery(
    key,
    () => fetchQueryFn(key, () => getSettingsHistory(augurCode, offset, limit)),
    {
      keepPreviousData: true,
    }
  );
};

export function getAugur(augurCode: string): CompletedApiRequest<Augur> {
  return apiRequest(`/api/augurs/${augurCode}`);
}

export const useAugur = (augurCode: string): UseQueryResult<Augur> => {
  const key = augurKeys.augur(augurCode);
  return useQuery(key, () => fetchQueryFn(key, () => getAugur(augurCode)));
};

export function getAugurs(
  habitatCode?: string,
  archived?: boolean
): CompletedApiRequest<Augur[]> {
  const query = qs.stringify(
    { habitatCode, archived },
    { addQueryPrefix: true }
  );
  return apiRequest(`/api/augurs${query}`);
}

export const useAugurs = (
  habitatCode?: string,
  archived?: boolean
): UseQueryResult<Augur[]> => {
  const key = augurKeys.augurs(habitatCode, archived);
  return useQuery(key, () =>
    fetchQueryFn(key, () => getAugurs(habitatCode, archived))
  );
};

export function getAugurNames(
  habitatCode?: string
): CompletedApiRequest<NamesResponseBody> {
  const query = qs.stringify({ habitatCode }, { addQueryPrefix: true });
  return apiRequest(`/api/augurs/names${query}`);
}

export function useAugurNames(
  habitatCode?: string
): UseQueryResult<ResourceNames> {
  const key = augurKeys.names(habitatCode);
  return useQuery(key, async () => {
    return fetchQueryFn(key, () => getAugurNames(habitatCode));
  });
}

export function putAugur(augurCode: string, payload: PutAugurRequestBody) {
  return putApiRequest(`/api/augurs/${augurCode}`, payload);
}

export function useUpdateAugur(augurCode: string) {
  const queryClient = useQueryClient();
  const key = augurKeys.update(augurCode);
  return useMutation(
    key,
    (payload: PutAugurRequestBody) =>
      fetchQueryFn(key, () => putAugur(augurCode, payload)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: augurKeys.all(),
        });
      },
    }
  );
}

/**
 * An alternative to useUpdateAugur, where the augurCode is only passed later when invoking the mutation function
 * Useful for when you can't use hooks where you know the augur code and vice versa
 */
export function useUpdateAugurWithAugurCode(): UseMutationResult {
  const queryClient = useQueryClient();
  const key = augurKeys.updateWithAugurCode();
  return useMutation(
    key,
    ({
      augurCode,
      payload,
    }: {
      augurCode: string;
      payload: PutAugurRequestBody;
    }) => fetchQueryFn(key, () => putAugur(augurCode, payload)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: augurKeys.all(),
        });
      },
    }
  );
}

export function deleteAugur(augurCode: string): CompletedApiRequest {
  return deleteApiRequest(`/api/augurs/${augurCode}`);
}

export function useDeleteAugur(augurCode: string): UseMutationResult {
  const queryClient = useQueryClient();
  const key = augurKeys.delete(augurCode);
  return useMutation(
    key,
    () => fetchQueryFn(key, () => deleteAugur(augurCode)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: augurKeys.all(),
        });
      },
    }
  );
}

export function postAugur(augur: PostAugurRequestBody): CompletedApiRequest {
  return postApiRequest(`/api/augurs`, augur);
}

export function useAddAugur(): UseMutationResult {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const key = augurKeys.add();
  return useMutation(
    key,
    (augur: PostAugurRequestBody) => fetchQueryFn(key, () => postAugur(augur)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: augurKeys.all(),
        });
      },
      onError: (error) => {
        // only error notification is needed here, success notification is broadcast over sockets
        dispatch(
          sendNotification(
            notificationsMsgs.msgTitleAugurAddFailure.id,
            // @ts-ignore
            notificationsMsgs.msgDescriptionAugurAddFailure.id,
            errorType
          )
        );
      },
    }
  );
}

export function triggerLearningRun(augurCode: string) {
  return postApiRequest(`/api/augurs/${augurCode}/triggerlearningrun`);
}

export function triggerEvaluationRun(augurCode: string) {
  return postApiRequest(`/api/augurs/${augurCode}/triggerevaluationrun`);
}

export function triggerPredictionRun(augurCode: string) {
  return postApiRequest(`/api/augurs/${augurCode}/triggerpredictionrun`);
}

import React, { FC, useEffect } from 'react';

import Attributes from './Attributes';
import { dataToForm } from './Attributes.form';
import ChangePassword from './change-password/ChangePassword';
import Groups from './Groups';
import Habitats from './Habitats';
import Roles from './Roles';

import './styles.scss';
import { RouteComponentProps } from 'react-router';

import {
  Group,
  Groups as GroupsType,
  Roles as RolesType,
} from '../../../../store/admin/state.types';
import BreadCrumbs from '../../../atoms/bread-crumbs/BreadCrumbs';
import { ButtonProps } from '../../../atoms/button/Button';
import TabLineBubbles from '../../../molecules/tab-line-bubbles/TabLineBubbles';

import { FiKey, FiTag, FiUnlock } from 'react-icons/fi';
import { Route, Switch, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { User } from 'common/dist/types/users';
import commonMessages from 'common/dist/messages/common';
import adminMessages from 'common/dist/messages/admin';

type Params = {
  userId: string;
};

export type Props = {
  /** ID of the user to display (taken from the URL) */
  userId: string;
  /** Details of the user that is currently displayed (not of the user logged in!) */
  user: {
    data?: User;
    loading?: boolean;
    loaded?: boolean;
    error?: string;
  };
  /** Details about all (not only the ones the user has assigned) available roles in the AltaSigma system. */
  roles: RolesType;
  /** Details about all (not only the ones the user has assigned) available groups in the AltaSigma system. */
  groups: GroupsType;

  /** Dispatches an action to load the user details for a given user */
  adminLoadUserDetails: (userId: string) => void;
  /** Dispatches an action to load the available roles */
  loadRoles: () => void;
  /** Dispatches an action to load the available groups */
  loadGroups: (fetchPermissions: boolean) => void;
  /** Add a User-RealmRole mapping */
  addRealmRoleMapping: (
    userId: string,
    roleId: string,
    roleName: string
  ) => void;
  /** Remove a User-RealmRole mapping */
  removeRealmRoleMapping: (
    userId: string,
    roleId: string,
    roleName: string
  ) => void;
  /** Add a User-Group mapping */
  joinGroup: (userId: string, groupId: string) => void;
  /** Remove a User-Group mapping */
  leaveGroup: (userId: string, groupId: string) => void;
} & RouteComponentProps<Params>;

enum TabNames {
  AUTHORIZATION = 'authorization',
  ATTRIBUTES = 'attributes',
  PASSWORD = 'password',
}

const AdminUserDetails: FC<Props> = ({
  userId,
  user,
  roles,
  groups,
  adminLoadUserDetails,
  loadRoles,
  loadGroups,
  addRealmRoleMapping,
  removeRealmRoleMapping,
  joinGroup,
  leaveGroup,
}) => {
  const intl = useIntl();
  const location = useLocation();

  useEffect(() => {
    if (userId) adminLoadUserDetails(userId);
    loadRoles();
    loadGroups(false);
  }, [userId, adminLoadUserDetails, loadRoles, loadGroups]);

  const getActiveCategory = () => {
    if (!location.pathname) return null;
    const parts = location.pathname.split('/');
    if (parts.length < 7) return TabNames.AUTHORIZATION;

    const active = parts[6]; // TODO No good idea to pick out the active category like this ...
    return active || TabNames.AUTHORIZATION;
  };

  const userData = user?.data || {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    attributes: {},
  };
  const rolesData = roles.data || [];
  const groupsData: [Group[], Group[]] = _.partition(groups.data || [], (g) =>
    _.isEqual(g.attributes.isHabitat, ['true'])
  );
  const habitats = groupsData[0];
  const nonHabitatGroups = groupsData[1];

  const routeAuthorization = `/app/admin/users/user/${userId}/${TabNames.AUTHORIZATION}`;
  const routeAttributes = `/app/admin/users/user/${userId}/${TabNames.ATTRIBUTES}`;
  const routePassword = `/app/admin/users/user/${userId}/${TabNames.PASSWORD}`;

  const tabLineButtons = [
    {
      id: TabNames.AUTHORIZATION,
      linkTo: routeAuthorization,
      Icon: () => <FiUnlock size={16} />,
      label: 'Authorization',
    },
    {
      id: TabNames.ATTRIBUTES,
      linkTo: routeAttributes,
      Icon: () => <FiTag size={16} />,
      label: 'Attributes',
    },
    {
      id: TabNames.PASSWORD,
      linkTo: routePassword,
      Icon: () => <FiKey size={16} />,
      label: 'Password',
    },
  ] satisfies ButtonProps[];

  return (
    <div className={'AdminUserDetails'}>
      <BreadCrumbs
        backToProps={[
          {
            linkTo: `/app/admin/users/list`,
            onClick: () => {},
            label: intl.formatMessage(commonMessages.backTo, {
              origin: intl.formatMessage(adminMessages.usersOrigin),
            }),
          },
        ]}
      />

      <div className={'AdminUserDetails--header'}>
        <div
          className={
            'AdminUserDetails--part AdminUserDetails--part-user-details'
          }
        >
          <span className={'AdminUserDetails--part-headline'}>
            User Details
          </span>
          <div className={'AdminUserDetails--part-details-container'}>
            <div className={'AdminUserDetails--part-details-line'}>
              <span className={'AdminUserDetails--part-details-key'}>
                E-Mail:{' '}
              </span>
              <span className={'AdminUserDetails--part-details-value'}>
                {userData.email}
              </span>
            </div>
            <div className={'AdminUserDetails--part-details-line'}>
              <span className={'AdminUserDetails--part-details-key'}>
                First Name:{' '}
              </span>
              <span className={'AdminUserDetails--part-details-value'}>
                {userData.firstName}
              </span>
            </div>
            <div className={'AdminUserDetails--part-details-line'}>
              <span className={'AdminUserDetails--part-details-key'}>
                Last Name:{' '}
              </span>
              <span className={'AdminUserDetails--part-details-value'}>
                {userData.lastName}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={'AdminUserDetails--tabs-line'}>
        <TabLineBubbles
          buttons={tabLineButtons}
          activeButton={getActiveCategory()}
        />
      </div>

      <div className={'AdminUserDetails--tabs-content'}>
        <Switch>
          <Route path={routePassword}>
            <div className={'AdminUserDetails--part part-password'}>
              <FormattedMessage
                id={'account.password.title'}
                defaultMessage={'Password'}
              >
                {(text) => (
                  <span className={'AdminUserDetails--part-headline'}>
                    {text}
                  </span>
                )}
              </FormattedMessage>
              <ChangePassword userId={userId} />
            </div>
          </Route>

          <Route path={routeAttributes}>
            <div className={'AdminUserDetails--part part-attributes'}>
              <span className={'AdminUserDetails--part-headline'}>
                Attributes
              </span>
              <Attributes
                attributesData={dataToForm(userData.attributes || {})}
                userId={userData.id}
              />
            </div>
          </Route>

          <Route
            path={[
              routeAuthorization,
              `/app/admin/users/user/${userId}`, // Act as a fallback if no tab was selected yet
            ]}
          >
            <div className={'AdminUserDetails--part part-user-roles'}>
              <span className={'AdminUserDetails--part-headline'}>Roles</span>
              <Roles
                rolesData={rolesData}
                usersRoles={user?.data?.realmRoles || []}
                userId={userData.id}
                addRealmRoleMapping={addRealmRoleMapping}
                removeRealmRoleMapping={removeRealmRoleMapping}
              />
            </div>

            <div className={'AdminUserDetails--part part-user-groups'}>
              <span className={'AdminUserDetails--part-headline'}>Groups</span>
              <Groups
                groupsData={nonHabitatGroups}
                usersGroups={user?.data?.groups || []}
                userId={userData.id}
                joinGroup={joinGroup}
                leaveGroup={leaveGroup}
              />
            </div>

            <div className={'AdminUserDetails--part part-user-groups'}>
              <span className={'AdminUserDetails--part-headline'}>
                Habitats
              </span>
              <Habitats
                groupsData={habitats}
                usersGroups={user?.data?.groups || []}
                userId={userData.id}
                joinGroup={joinGroup}
                leaveGroup={leaveGroup}
              />
            </div>
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default AdminUserDetails;

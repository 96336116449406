import React, { FC } from 'react';
import styles from './styles.module.scss';
import { FiCode } from 'react-icons/fi';
import { colors, hashString } from '../../atoms/avatar-icon/AvatarIcon';
import { repoTypeToSpeaking } from 'common/dist/types/repository';
import classNames from 'classnames';

export type ProjectLinkProps = {
  name: string;
  repoType?: string;
};

const ProjectLink: FC<ProjectLinkProps> = (props) => {
  const { name, repoType } = props;

  return (
    <div className={styles.projectLink}>
      <div
        className={styles.projectIcon}
        style={{
          backgroundColor: colors[hashString(name, colors.length)],
        }}
      >
        {(name || '')
          .split(' ')
          .map((w) => w.charAt(0))
          .slice(0, 2)
          .join('')}
      </div>
      <div className={classNames(styles.textContainer, {
        [styles.noActiveProject]: !repoType
      })}>
        <span title={name} className={styles.title}>
          {name}
        </span>
        <span className={styles.description}>
          {repoType ? repoTypeToSpeaking[repoType] : 'Click to select project'}
        </span>
      </div>
    </div>
  );
};

export default ProjectLink;

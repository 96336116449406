import classNames from 'classnames';
import { NotificationType } from 'common/dist/types/notification';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { removeNotification } from '../../redux/modules/notifications.module';

interface NotificationProps {
  notification: NotificationType;
}

export const Notification: FC<NotificationProps> = ({ notification }) => {
  const dispatch = useDispatch();
  const {
    title,
    description,
    descriptionValues,
    titleValues,
    type,
    remove,
    id,
    progress = 0,
  } = notification;

  const handleClick = () => {
    dispatch(removeNotification(id));
  };

  const notificationClassName = classNames(
    'notification',
    { 'notification--red': type === 'error' },
    { 'notification--green': type === 'event' }
  );

  const titleClassName = classNames(
    'notification__title',
    { 'notification__title--red': type === 'error' },
    { 'notification__title--green': type === 'event' }
  );

  return (
    <div className={notificationClassName} onClick={handleClick}>
      <h4 className={titleClassName}>
        <FormattedMessage id={title} values={titleValues} />
      </h4>
      <div className='notification__description'>
        <FormattedMessage id={description} values={descriptionValues} />
      </div>
      {progress > 0 && (
        <div className='notification__progress-container'>
          <div className='notification__progress-back' />
          <div
            className='notification__progress-track'
            style={{ width: `${progress}%` }}
          />
          <span className='notification__progress-state'>{progress}%</span>
        </div>
      )}
    </div>
  );
};

import React, { FC } from 'react';
import { useSelectedDirPath } from '../../hooks';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import { WORKBENCH_FILENAMES } from '../../part-right/config';
import errorStyles from '../../../../../scss/partials/error.module.scss';
import NotebookApi from '../../../../core/api/workbench/git.notebook';

export interface SwaggerProps {
  /** The userId of the logged in user */
  notebookUser: string;
  activeProjectPath: string;
}

const Swagger: FC<SwaggerProps> = ({ notebookUser, activeProjectPath }) => {
  if (activeProjectPath === '') {
    return <div className={errorStyles.error}>Invalid directory</div>;
  }
  const path = '/' + activeProjectPath;
  const url =
    new NotebookApi(notebookUser).baseUrl() + '/modules/openapi' + path;
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <iframe
        src={`/api/swagger-ui-dist/?url=${url}`}
        style={{ height: '100%', width: '100%' }}
      />
    </div>
  );
};

export default Swagger;

import { defineMessages } from 'react-intl';

// For notifications the defaultMessage will not be passed in code, so it should not be empty '', so that phraseapp does its thing
export default defineMessages({
  titleS3BucketCreation: {
    id: 'notifications.title.s3_bucket.creation',
    defaultMessage: 'S3 Bucket',
  },
  descriptionS3BucketCreated: {
    id: 'notifications.description.s3_bucket.created',
    defaultMessage: 'S3 Bucket {bucketName} has been created',
  },
  descriptionS3BucketExists: {
    id: 'notifications.description.s3_bucket.exists',
    defaultMessage: 'S3 Bucket {bucketName} already exists',
  },
  descriptionS3BucketError: {
    id: 'notifications.description.s3_bucket.error',
    defaultMessage: 'S3 Bucket {bucketName} could not be created',
  },
  descriptionNoDefaultDataSource: {
    id: 'notifications.description.no_default_data_source',
    defaultMessage: "No default Data Source, couldn't create the Bucket",
  },
  msgTitlePermissionSetErr: {
    id: 'notifications.title.permissions.set.error',
    defaultMessage: 'Permissions could not be set',
  },
  msgDescriptionPermissionSetErr: {
    id: 'notifications.description.permissions.set.error.details',
    defaultMessage: 'Permissions could not be set',
  },
  msgTitlePermissionSyncErr: {
    id: 'notifications.title.permissions.sync.error',
    defaultMessage: 'Permissions could not be synced',
  },
  msgDescriptionPermissionSyncErr: {
    id: 'notifications.description.permissions.sync.error.details',
    defaultMessage: 'Will automatically retry later.',
  },
  msgTitleJobGroupAddSuccess: {
    id: 'notifications.title.jobGroup_add_success',
    defaultMessage: 'Job Group added',
  },
  msgDescriptionJobGroupAddSuccess: {
    id: 'notifications.description.jobGroup_add_success',
    defaultMessage: 'The Job Group was added successfully',
  },
  msgTitleJobGroupAddFailure: {
    id: 'notifications.title.jobGroup_add_failure',
    defaultMessage: 'Adding Job Group failed',
  },
  msgTitleJobGroupUpdateSuccess: {
    id: 'notifications.title.jobGroup_update_success',
    defaultMessage: 'Job Group updated',
  },
  msgDescriptionJobGroupUpdateSuccess: {
    id: 'notifications.description.jobGroup_update_success',
    defaultMessage: 'The Job Group was updated successfully',
  },
  msgTitleJobGroupUpdateFailure: {
    id: 'notifications.title.jobGroup_update_failure',
    defaultMessage: 'Updating Job Group failed',
  },
  msgTitleJobGroupDeleteSuccess: {
    id: 'notifications.title.jobGroup_delete_success',
    defaultMessage: 'Job Group deleted',
  },
  msgDescriptionJobGroupDeleteSuccess: {
    id: 'notifications.description.jobGroup_delete_success',
    defaultMessage: 'The Job Group was deleted successfully',
  },
  msgTitleJobGroupDeleteFailure: {
    id: 'notifications.title.jobGroup_delete_failure',
    defaultMessage: 'Deleting Job Group failed',
  },
  msgTitleJobGroupRunSuccess: {
    id: 'notifications.title.jobGroup_run_success',
    defaultMessage: 'Job Group triggered',
  },
  msgDescriptionJobGroupRunSuccess: {
    id: 'notifications.description.jobGroup_run_success',
    defaultMessage: 'The Job Group was successfully added to the Job Queue',
  },
  msgTitleJobGroupRunFailure: {
    id: 'notifications.title.jobGroup_run_failure',
    defaultMessage: 'Triggering Job Group failed',
  },
  msgTitleScheduleAddSuccess: {
    id: 'notifications.title.schedule_add_success',
    defaultMessage: 'Schedule added',
  },
  msgDescriptionScheduleAddSuccess: {
    id: 'notifications.description.schedule_add_success',
    defaultMessage: 'The Schedule was added successfully',
  },
  msgTitleScheduleAddFailure: {
    id: 'notifications.title.schedule_add_failure',
    defaultMessage: 'Adding Schedule failed',
  },
  msgTitleScheduleUpdateSuccess: {
    id: 'notifications.title.schedule_update_success',
    defaultMessage: 'Schedule updated',
  },
  msgDescriptionScheduleUpdateSuccess: {
    id: 'notifications.description.schedule_update_success',
    defaultMessage: 'The Schedule was updated successfully',
  },
  msgTitleScheduleUpdateFailure: {
    id: 'notifications.title.schedule_update_failure',
    defaultMessage: 'Updating Schedule failed',
  },
  msgTitleScheduleDeleteSuccess: {
    id: 'notifications.title.schedule_delete_success',
    defaultMessage: 'Schedule deleted',
  },
  msgDescriptionScheduleDeleteSuccess: {
    id: 'notifications.description.schedule_delete_success',
    defaultMessage: 'The Schedule was deleted successfully',
  },
  msgTitleScheduleDeleteFailure: {
    id: 'notifications.title.schedule_delete_failure',
    defaultMessage: 'Deleting Schedule failed',
  },
  msgTitleAddedHabitat: {
    id: 'notifications.title.added_habitat',
    defaultMessage: 'Added Habitat',
  },
  msgDescriptionAddedHabitat: {
    id: 'notifications.description.habitat_was_added',
    defaultMessage: 'Habitat {habitatName} was added.',
  },
  msgTitleAddHabitatFailed: {
    id: 'notifications.title.add_habitat_fail',
    defaultMessage: 'Add Habitat failed',
  },
  msgDescriptionAddHabitatFailed: {
    id: 'notifications.description.habitat_could_not_be_added',
    defaultMessage: 'Habitat {habitatName} could not be added.',
  },
  msgTitleEditedHabitat: {
    id: 'notifications.title.edited_habitat',
    defaultMessage: 'Edit Habitat',
  },
  msgDescriptionEditedHabitat: {
    id: 'notifications.description.habitat_was_edited',
    defaultMessage: 'Habitat {habitatName} was edited.',
  },
  msgTitleEditHabitatFailed: {
    id: 'notifications.title.edit_habitat_fail',
    defaultMessage: 'Edit Habitat failed',
  },
  msgDescriptionEditHabitatFailed: {
    id: 'notifications.description.habitat_could_not_be_edited',
    defaultMessage: 'Habitat {habitatName} could not be edited.',
  },
  msgTitleDeletedHabitat: {
    id: 'notifications.title.deleted_habitat',
    defaultMessage: 'Delete Habitat',
  },
  msgDescriptionDeletedHabitat: {
    id: 'notifications.description.habitat_was_deleted',
    defaultMessage: 'Habitat {habitatName} was deleted.',
  },
  msgTitleDeletedHabitatFailed: {
    id: 'notifications.title.deleted_habitat_fail',
    defaultMessage: 'Delete Habitat failed',
  },
  msgDescriptionDeletedHabitatFailed: {
    id: 'notifications.description.habitat_could_not_be_deleted',
    defaultMessage: 'The deletion of Habitat {habitatName} failed.',
  },
  msgJobCanceledTitle: {
    id: 'notifications.title.job.canceled',
    defaultMessage: 'Job canceled!',
  },
  msgJobCanceledDescription: {
    id: 'notifications.description.job.canceled',
    defaultMessage: 'The Job was cancelled successfully!',
  },
  msgJobGroupCanceledTitle: {
    id: 'notifications.title.jobgroup.canceled',
    defaultMessage: 'Job Group canceled!',
  },
  msgJobGroupCanceledDescription: {
    id: 'notifications.description.jobgroup.canceled',
    defaultMessage: 'The Job Group was cancelled successfully!',
  },
  msgTitleUploadFile: {
    id: 'notifications.title.upload_file_s3',
    defaultMessage: 'Upload File',
  },
  msgDescriptionUploadFile: {
    id: 'notifications.description.upload_file_s3',
    defaultMessage: 'Starting file upload to S3',
  },
  msgDescriptionUploadFileSuccess: {
    id: 'notifications.description.upload_file_s3_success',
    defaultMessage: 'File upload to S3 completed',
  },
  msgDescriptionUploadFileFailure: {
    id: 'notifications.description.upload_file_s3_failure',
    defaultMessage: 'File upload to S3 failed',
  },
  msgTitleUploadFileCass: {
    id: 'notifications.title.upload_file_cass',
    defaultMessage: 'Upload File',
  },
  msgDescriptionUploadFileCass: {
    id: 'notifications.description.upload_file_cass',
    defaultMessage: 'Starting file upload to Cassandra',
  },
  msgDescriptionUploadFileCassSuccess: {
    id: 'notifications.description.upload_file_cass_success',
    defaultMessage: 'File upload to Cassandra completed',
  },
  msgDescriptionUploadFileCassFailure: {
    id: 'notifications.description.upload_file_cass_failure',
    defaultMessage: 'File upload to Cassandra failed',
  },
  msgTitleCCBuildTriggered: {
    id: 'notifications.title.CCBuildTriggered',
    defaultMessage: 'Triggered successfully',
  },
  msgDescriptionCCBuildTriggered: {
    id: 'notifications.description.CCBuildTriggered',
    defaultMessage: 'The code capsule build was triggered successfully',
  },
  msgTitleCCBuildTriggeredFailed: {
    id: 'notifications.title.CCBuildTriggered_failed',
    defaultMessage: 'Code capsule build failed',
  },
  msgDescriptionCCBuildTriggeredFailed: {
    id: 'notifications.description.CCBuildTriggered_failed',
    defaultMessage: 'The code capsule build trigger failed',
  },
  msgTitleAppBuildTriggered: {
    id: 'notifications.title.AppBuildTriggered',
    defaultMessage: 'Triggered successfully',
  },
  msgDescriptionAppBuildTriggered: {
    id: 'notifications.description.AppBuildTriggered',
    defaultMessage: 'The app capsule build was triggered successfully',
  },
  msgTitleAppBuildTriggeredFailed: {
    id: 'notifications.title.AppBuildTriggered_failed',
    defaultMessage: 'App build failed',
  },
  msgDescriptionAppBuildTriggeredFailed: {
    id: 'notifications.description.AppBuildTriggered_failed',
    defaultMessage: 'The app build trigger failed',
  },
  msgTitleTemplateCreateSuccess: {
    id: 'notifications.title.template_create_success',
    defaultMessage: 'Template created',
  },
  msgDescriptionTemplateCreateSuccess: {
    id: 'notifications.description.template_create_success',
    defaultMessage: 'The template was created successfully',
  },
  msgTitleTemplateCreateFailure: {
    id: 'notifications.title.template_create_failure',
    defaultMessage: 'Creating template failed',
  },
  msgDescriptionTemplateCreateFailure: {
    id: 'notifications.description.template_create_failure',
    defaultMessage: 'Unable to create template',
  },
  msgTitleBackupCreateSuccess: {
    id: 'notifications.title.backup_create_success',
    defaultMessage: 'Backup created',
  },
  msgDescriptionBackupCreateSuccess: {
    id: 'notifications.description.backup_create_success',
    defaultMessage: 'The backup was created successfully',
  },
  msgTitleBackupCreateFailure: {
    id: 'notifications.title.backup_create_failure',
    defaultMessage: 'Creating backup failed',
  },
  msgDescriptionBackupCreateFailure: {
    id: 'notifications.description.backup_create_failure',
    defaultMessage: 'Unable to create backup',
  },
  msgTitleIpynbFileCreateFailure: {
    id: 'notifications.title.ipynb_file_create_failure',
    defaultMessage: 'Creating file failed',
  },
  msgDescriptionIpynbFileCreateFailure: {
    id: 'notifications.description.ipynb_file_create_failure',
    defaultMessage: 'Files with .ipynb are not allowed',
  },
  msgTitleAugurAddFailure: {
    id: 'notifications.title.augur_add_failure',
    defaultMessage: 'Adding Augur failed',
  },
  msgDescriptionAugurAddFailure: {
    id: 'notifications.description.augur_add_failure',
    defaultMessage: 'Unable to create augur',
  },
  msgTitleNewAugurExists: {
    id: 'notifications.title.augur_exists',
    defaultMessage: 'Validation error',
  },
  msgDescriptionNewAugurExists: {
    id: 'notifications.description.augur_exists',
    defaultMessage: 'Augur {augurName} already exists',
  },
  //MODULE
  msgTitleModuleAddSuccess: {
    id: 'notifications.title.module_add_success',
    defaultMessage: 'Module added',
  },
  msgDescriptionModuleAddSuccess: {
    id: 'notifications.description.module_add_success',
    defaultMessage: 'The Module was added successfully',
  },
  msgTitleModuleAddFailure: {
    id: 'notifications.title.module_add_failure',
    defaultMessage: 'Adding Module failed',
  },
  msgDescriptionModuleAddFailure: {
    id: 'notifications.description.module_add_failure',
    defaultMessage: 'Adding Module failed with an error.',
  },
  msgTitleModuleUpdateSuccess: {
    id: 'notifications.title.module_update_success',
    defaultMessage: 'Module updated',
  },
  msgDescriptionModuleUpdateSuccess: {
    id: 'notifications.description.module_update_success',
    defaultMessage: 'The Module was updated successfully',
  },
  msgTitleModuleUpdateFailure: {
    id: 'notifications.title.module_update_failure',
    defaultMessage: 'Updating Module failed',
  },
  msgDescriptionModuleUpdateFailure: {
    id: 'notifications.description.module_update_failure',
    defaultMessage: 'Updating Module failed with an error.',
  },
  msgTitleModuleBuildSuccess: {
    id: 'notifications.title.module_build_success',
    defaultMessage: 'Build Module',
  },
  msgDescriptionModuleBuildSuccess: {
    id: 'notifications.description.module_build_success',
    defaultMessage: 'The Module build was triggered successfully',
  },
  msgTitleModuleBuildFailure: {
    id: 'notifications.title.module_build_failure',
    defaultMessage: 'Build Module failed',
  },
  msgDescriptionModuleBuildFailure: {
    id: 'notifications.description.module_build_failure',
    defaultMessage: 'Building the Module failed with an error.',
  },
  msgTitleBucketCreateSuccess: {
    id: 'notifications.title.bucket_create_success',
    defaultMessage: 'Bucket create',
  },
  msgDescriptionBucketCreateSuccess: {
    id: 'notifications.description.bucket_create_success',
    defaultMessage: 'The Bucket was created successfully.',
  },
  msgTitleBucketCreateFailure: {
    id: 'notifications.title.bucket_create_failure',
    defaultMessage: 'Creating Bucket failed',
  },
  msgDescriptionBucketCreateFailure: {
    id: 'notifications.description.bucket_create_failure',
    defaultMessage: 'Creating Bucket failed with an error.',
  },
  titleWorkbenchTerminalSessionClosed: {
    id: 'notifications.title.workbench_terminal_session_closed',
    defaultMessage: 'Terminal session closed.',
  },
  titleWorkbenchTerminalSessionStarted: {
    id: 'notifications.title.workbench_terminal_session_started',
    defaultMessage: 'Terminal session started.',
  },
  titleWorkbenchTerminalSessionDeleted: {
    id: 'notifications.title.workbench_terminal_session_deleted',
    defaultMessage: 'Terminal session deleted.',
  },
  titleWorkbenchTerminalInterrupt: {
    id: 'notifications.title.workbench_terminal_interrupt',
    defaultMessage: 'Terminal interrupt send.',
  },
  titleWorkbenchTerminalPythonGenerated: {
    id: 'notifications.title.workbench_terminal_python_generated',
    defaultMessage: 'Python Classes generated',
  },
  titleWorkbenchTerminalPythonGenerationFailed: {
    id: 'notifications.title.workbench_terminal_python_generation_failed',
    defaultMessage: 'Python Classes Generation failed',
  },
  titleMergeSuccess: {
    id: 'notifications.title.merge.success',
    defaultMessage: 'The Merge was successfully.',
  },
  titleMergeFailed: {
    id: 'notifications.title.merge.failed',
    defaultMessage: 'The Merge failed',
  },
  titleRemoveAllArtifactsSuccess: {
    id: 'notifications.title.remove.all.artifacts.success',
    defaultMessage: 'Removing all artifacts was successful.',
  },
  titleRemoveAllArtifactsFailed: {
    id: 'notifications.title.remove.all.artifacts.failed',
    defaultMessage: 'Removing all artifacts failed!',
  },
  // APP
  msgTitleAppCreateSuccess: {
    id: 'notifications.title.app_create_success',
    defaultMessage: 'App created',
  },
  msgDescriptionAppCreateSuccess: {
    id: 'notifications.description.app_create_success',
    defaultMessage: 'The App was created successfully',
  },
  msgTitleAppCreateFailure: {
    id: 'notifications.title.app_create_failure',
    defaultMessage: 'Creating App failed',
  },
  msgDescriptionAppCreateFailure: {
    id: 'notifications.description.app_create_failure',
    defaultMessage: 'Creating App failed with an error.',
  },
  // CODE CAPSULE
  msgTitleCodeCapsuleCreateSuccess: {
    id: 'notifications.title.code_capsule_create_success',
    defaultMessage: 'Code Capsule created',
  },
  msgDescriptionCodeCapsuleCreateSuccess: {
    id: 'notifications.description.code_capsule_create_success',
    defaultMessage: 'The Code Capsule was created successfully',
  },
  msgTitleCodeCapsuleCreateFailure: {
    id: 'notifications.title.code_capsule_create_failure',
    defaultMessage: 'Creating Code Capsule failed',
  },
  msgDescriptionCodeCapsuleCreateFailure: {
    id: 'notifications.description.code_capsule_create_failure',
    defaultMessage: 'Creating Code Capsule failed with an error.',
  },
  // CODE CAPSULE
  msgTitlePlainRepositoryCreateSuccess: {
    id: 'notifications.title.plain_repository_create_success',
    defaultMessage: 'Plain Repository created',
  },
  msgDescriptionPlainRepositoryCreateSuccess: {
    id: 'notifications.description.plain_repository_create_success',
    defaultMessage: 'The Plain Repository was created successfully',
  },
  msgTitlePlainRepositoryCreateFailure: {
    id: 'notifications.title.plain_repository_create_failure',
    defaultMessage: 'Creating Plain Repository failed',
  },
  msgDescriptionPlainRepositoryCreateFailure: {
    id: 'notifications.description.plain_repository_create_failure',
    defaultMessage: 'Creating Plain Repository failed with an error.',
  },
});

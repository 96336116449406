import augurElementsMessages from 'common/dist/messages/augurs.elements';
import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  ConfigDropdownSelectCFEConfig,
  DropdownSelectCFEConfig,
  OptionsType,
} from './type';
import { IntlTextInputLine } from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import Table, { RenderColumn } from '../../../../table/Table';
import { FiPlus } from 'react-icons/fi';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';
import {
  ElementOptionComponent,
  ElementOptionType,
} from '../../common/ElementOptionComponent';
import { DropdownSelectInput } from '../../../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { IntlCheckbox } from '../../../../../atoms/react-hook-form-input-elements/checkbox/Checkbox';
import { ConfigPageElementForm } from '../../../../../pages/augur/utils/config.form';
import { ConfigFormComponentProps } from '../../../common/utils';
import styles from '../../../../../organisms/element-configuration/styles.module.scss';
import { useIntl } from 'react-intl';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';

type OptionsTypeWithIndex = OptionsType & { index: number };

const DropdownSelectCFEConfigFormComponent: FC<
  ConfigFormComponentProps<DropdownSelectCFEConfig>
> = ({ configValue, onConfigChange, elements }) => {
  const { control, getValues, setValue } =
    useForm<ConfigDropdownSelectCFEConfig>({ defaultValues: configValue });

  const intl = useIntl();

  const updateConfig = () => {
    const config = getValues();
    onConfigChange(config);
  };

  const options: ElementOptionType[] = elements
    .filter(
      (element) => element.type !== SETTINGS_ELEMENT_TYPES.DROPDOWN_SELECT
    )
    .map((element: ConfigPageElementForm) => ({
      ...element,
      label: element.title,
      value: element.uuid,
    }));

  const dataWithIndex =
    configValue?.options.source === 'hard-coded'
      ? configValue?.options.value.map((option, index) => ({
          ...option,
          index,
        }))
      : [];

  const renderColumns: RenderColumn<
    OptionsTypeWithIndex,
    keyof OptionsTypeWithIndex
  >[] = [
    {
      key: 'label',
      renderHeader: () => <span>Label</span>,
      width: '25%',
      renderCell: (label, row) => (
        <Controller
          name={`options.value.${row.index}.label`}
          control={control}
          render={({ field }) => (
            <IntlTextInputLine
              label={augurElementsMessages.label}
              placeholder={`Option ${row.index + 1}`}
              {...field}
              onChange={(e) => {
                field.onChange(e);
                updateConfig();
              }}
            />
          )}
        />
      ),
    },
    {
      key: 'value',
      renderHeader: () => <span>Value</span>,
      width: '25%',
      renderCell: (label, row) => (
        <Controller
          name={`options.value.${row.index}.value`}
          control={control}
          render={({ field }) => (
            <IntlTextInputLine
              label={augurElementsMessages.value}
              placeholder={`Option ${row.index + 1}`}
              {...field}
              onChange={(e) => {
                field.onChange(e);
                updateConfig();
              }}
            />
          )}
        />
      ),
    },
    {
      key: 'dependants',
      renderHeader: () => <span>Dependants</span>,
      renderCell: (label, row) => (
        <Controller
          name={`options.value.${row.index}.dependants`}
          control={control}
          render={({ field }) => (
            <DropdownSelectInput<ElementOptionType, true>
              options={options}
              label={intl.formatMessage(augurElementsMessages.dependants)}
              placeholder={intl.formatMessage(
                augurElementsMessages.selectDependants
              )}
              onChange={(options) => {
                const updatedUUIDs = options.map((option) => option.uuid);
                field.onChange(updatedUUIDs);
                updateConfig();
              }}
              value={options.filter((option) =>
                field.value?.includes(option.uuid)
              )}
              customComponents={{ Option: ElementOptionComponent }}
              isMulti
              appearance={'multiLines'}
            />
          )}
        />
      ),
    },
  ];

  const addNewRow = () => {
    const currentOptions = getValues('options.value');
    const newOption = {
      label: '',
      value: '',
      dependants: [],
    };
    setValue('options.value', [...currentOptions, newOption]);
    updateConfig();
  };

  return (
    <div className={styles.verticalConfigLayout}>
      <Controller
        name='placeholder.value'
        control={control}
        render={({ field }) => (
          <IntlTextInputLine
            label={augurElementsMessages.placeholder}
            placeholder={augurElementsMessages.placeholder}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              updateConfig();
            }}
          />
        )}
      />

      <Controller
        name='isMulti.value'
        control={control}
        render={({ field }) => (
          <IntlCheckbox
            label={augurElementsMessages.isMulti}
            {...field}
            checked={field.value}
            onChange={() => {
              field.onChange(!field.value);
              updateConfig();
            }}
          />
        )}
      />
      <Controller
        name='isClearable.value'
        control={control}
        render={({ field }) => (
          <IntlCheckbox
            label={augurElementsMessages.isClearable}
            {...field}
            checked={field.value}
            onChange={() => {
              field.onChange(!field.value);
              updateConfig();
            }}
          />
        )}
      />
      <Controller
        name='isSearchable.value'
        control={control}
        render={({ field }) => (
          <IntlCheckbox
            label={augurElementsMessages.isSearchable}
            {...field}
            checked={field.value}
            onChange={() => {
              field.onChange(!field.value);
              updateConfig();
            }}
          />
        )}
      />

      <div className={commonStyles.optComponent}>
        <div className={styles.lightText}>Options</div>
        <div className={commonStyles.tableContainer}>
          <Table
            data={dataWithIndex}
            renderColumns={renderColumns}
            showHeader={false}
            removableRows={true}
            verticalAlignMiddle
            roundedBorder
            onRemoveRow={(removedRow) => {
              const updatedOptions = getValues('options.value').filter(
                (_, index) => index !== removedRow.index
              );
              setValue('options.value', updatedOptions);
              updateConfig();
            }}
          />
        </div>
        <div className={commonStyles.AddIconContainer}>
          <div className={commonStyles.AddIcon} onClick={() => addNewRow()}>
            <FiPlus size={18} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownSelectCFEConfigFormComponent;

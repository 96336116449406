import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import JobGroupDetails from './JobGroupDetails';
import { RootState } from '../../../../store/store';

export type RProps = RouteComponentProps<{
  jobGroupCode: string;
}>;

export function mapStateToProps(state: RootState, { match }: RProps) {
  const code = match.params.jobGroupCode;

  return {
    jobGroupCode: code,
    origin: state.jobDetails.origin,
  };
}

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(JobGroupDetails));

import { defineMessages } from 'react-intl';

export default defineMessages({
  addRepositoryWizardHeadline: {
    id: 'repository.wizard.headline',
    defaultMessage: 'Add new Repository',
  },
  addRepositoryWizardNameStepTitle: {
    id: 'repository.wizard.name.step.title',
    defaultMessage: 'Enter Name',
  },
  addRepositoryWizardNameStepDescription: {
    id: 'repository.wizard.name.step.description',
    defaultMessage: 'Enter a name for the repository.',
  },
  addRepositoryWizardNamePlaceholder: {
    id: 'repository.wizard.name.placeholder',
    defaultMessage: 'Enter Repository Name',
  },
  addRepositoryWizardSlugPlaceholder: {
    id: 'repository.wizard.slug.placeholder',
    defaultMessage: 'Enter Repository Slug',
  },
  addRepositoryWizardDescriptionPlaceholder: {
    id: 'repository.wizard.description.placeholder',
    defaultMessage: 'Enter Repository Description',
  },
  addRepositoryWizardDescriptionStepTitle: {
    id: 'repository.wizard.description.step.title',
    defaultMessage: 'Enter Description',
  },
  addRepositoryWizardDescriptionStepDescription: {
    id: 'repository.wizard.description.step.description',
    defaultMessage: 'Enter a description for the repository.',
  },
  addRepositoryWizardHabitatStepName: {
    id: 'repository.wizard.habitat.step.name',
    defaultMessage: 'Select Habitat',
  },
  addRepositoryWizardHabitatStepDescription: {
    id: 'repository.wizard.habitat.step.description',
    defaultMessage: 'Select a Habitat this Code Capsule will belong to.',
  },
  addAppWizardHeadline: {
    id: 'app.wizard.headline',
    defaultMessage: 'Add new App',
  },
  addCodeCapsuleWizardHeadline: {
    id: 'codeCapsule.wizard.headline',
    defaultMessage: 'Add new Code Capsule',
  },
});

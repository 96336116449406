import GroupRepresentation from '@keycloak/keycloak-admin-client/lib/defs/groupRepresentation';
import { Scope } from 'common/dist/types/keycloak';
import { User } from 'common/dist/types/users';
import _ from 'lodash';
import React, { FC } from 'react';
import { FiUser, FiUsers } from 'react-icons/fi';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Groups from './Groups';
import Habitats from './Habitats';
import styles from './styles.module.scss';
import Users from './Users';
import {
  Groups as GroupsState,
  Users as UsersState,
} from '../../../../store/admin/state.types';
import BackTo from '../../../atoms/back-to/BackTo';
import { ButtonProps } from '../../../atoms/button/Button';
import InfoBox from '../../../atoms/info-box/InfoBox';
import TabLineBubbles from '../../../molecules/tab-line-bubbles/TabLineBubbles';

export type GroupPermission = {
  [groupId: string]: {
    loading?: boolean;
    loaded?: boolean;
    error?: string;
    data?: {
      [resourceName: string]: {
        name: string;
      }[];
    };
  };
};
export type Props = {
  details: {
    headline: MessageDescriptor;
    rows: {
      key: MessageDescriptor;
      value: string;
    }[];
  };
  /** For example: habitat:HAB123 */
  resourceName: string;
  /** For example: habitat */
  resourceType: string;
  /** List of available scopes for the groups / users */
  scopes: Scope[];

  messagesGroupPermissions: {
    subTitle: MessageDescriptor;
    description?: MessageDescriptor;
  };

  messagesUserPermissions: {
    subTitle: MessageDescriptor;
    description?: MessageDescriptor;
  };

  /** Base URL to use for the routing */
  baseUrl: string;

  backToUrl?: string;
  showBackButton?: boolean;
  backButtonLabel?: string;

  history?: {
    // Injected by redux-router
    location: {
      pathname: string;
    };
  };

  /* --- Groups --- */
  groups: GroupsState;
  /** Trigger loading the groups */
  loadGroups: (fetchPermissions: boolean) => void;

  /* --- Users --- */
  users: UsersState;

  userPermissions?: {
    [userId: string]: {
      loading?: boolean;
      loaded?: boolean;
      error?: string;
      data?: {
        [resourceName: string]: {
          name: string;
        }[];
      };
    };
  };

  loadUsersList: (fetchPermissions: boolean) => void;
  addUserPermission: (
    resourceName: string,
    resourceType: string,
    scopeName: string,
    user: User,
    refreshAction: () => void
  ) => void;
  removeUserPermission: (
    resourceName: string,
    scopeName: string,
    user: User,
    refreshAction: () => void
  ) => void;

  /* --- Group Permissions */
  /** Add a group-wise permission */
  addGroupPermission: (
    resourceName: string,
    resourceType: string,
    scopeName: string,
    group: GroupRepresentation,
    refreshAction: () => void
  ) => void;
  /** Remove a group-wise permission */
  removeGroupPermission: (
    resourceName: string,
    scopeName: string,
    group: GroupRepresentation,
    refreshAction: () => void
  ) => void;
  groupPermissions?: GroupPermission;
};

const ResourcePermissions: FC<Props> = (props) => {
  const {
    details,
    messagesGroupPermissions,
    messagesUserPermissions,
    resourceType,
    resourceName,
    scopes,
    baseUrl,
    backToUrl,
    showBackButton,
    backButtonLabel,
    users,
    userPermissions,
    loadUsersList,
    addUserPermission,
    removeUserPermission,
    groups,
    groupPermissions,
    loadGroups,
    addGroupPermission,
    removeGroupPermission,
  } = props;

  const { pathname, search } = useLocation();

  const buttons = [
    {
      id: 'users',
      linkTo: `${baseUrl}/users`,
      label: 'Users',
      Icon: () => <FiUser size={16} />,
    },
    {
      id: 'groups',
      linkTo: `${baseUrl}/groups`,
      label: 'Groups',
      Icon: () => <FiUsers size={16} />,
    },
    {
      id: 'habitats',
      linkTo: `${baseUrl}/habitats`,
      label: 'Habitats',
      Icon: () => <span style={{ fontSize: '16px' }} className={'icon-home'} />,
    },
  ] satisfies ButtonProps[];

  const activeButton = pathname.split('/')[baseUrl.split('/').length];

  const groupsData: [GroupRepresentation[], GroupRepresentation[]] =
    _.partition(groups.data || [], (g) =>
      _.isEqual(g.attributes.isHabitat, ['true'])
    );
  const habitats = groupsData[0];
  const nonHabitatGroups = groupsData[1];

  return (
    <div className={styles.resourcePermissions}>
      {showBackButton && (
        <div className={styles.backButtonParent}>
          <BackTo label={backButtonLabel} linkTo={backToUrl} />
        </div>
      )}

      <div className={styles.resourceDetails}>
        <FormattedMessage {...details.headline}>
          {(text) => <span className={styles.detailsHeadline}>{text}</span>}
        </FormattedMessage>
        <div className={styles.detailsList}>
          {details.rows.map((row, index) => (
            <div className={styles.detailsRow} key={index}>
              <FormattedMessage {...row.key}>
                {(text) => <span className={styles.detailsKey}>{text}</span>}
              </FormattedMessage>
              <span className={styles.detailsValue}>{row.value}</span>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.tabLineParent}>
        <TabLineBubbles buttons={buttons} activeButton={activeButton} />
      </div>

      <div className={styles.tabContentParent}>
        <Switch>
          <Route path={`${baseUrl}/users`}>
            <div className={styles.userPermissions}>
              <div className={styles.infoBoxParent}>
                <InfoBox>
                  <>
                    <FormattedMessage {...messagesUserPermissions.subTitle}>
                      {(text) => (
                        <span className={styles.permissionsSubHeadline}>
                          {text}
                        </span>
                      )}
                    </FormattedMessage>
                    {messagesUserPermissions.description && (
                      <FormattedMessage
                        {...messagesUserPermissions.description}
                      >
                        {(text) => (
                          <span className={styles.permissionsDescription}>
                            {text}
                          </span>
                        )}
                      </FormattedMessage>
                    )}
                  </>
                </InfoBox>
              </div>
              <Users
                resourceName={resourceName}
                resourceType={resourceType}
                scopes={scopes}
                users={users}
                userPermissions={userPermissions}
                loadUsersList={loadUsersList}
                addUserPermission={addUserPermission}
                removeUserPermission={removeUserPermission}
              />
            </div>
          </Route>

          <Route path={`${baseUrl}/groups`}>
            <div className={styles.groupPermissions}>
              <div className={styles.infoBoxParent}>
                <InfoBox>
                  <>
                    <FormattedMessage {...messagesGroupPermissions.subTitle}>
                      {(text) => (
                        <span className={styles.permissionsSubHeadline}>
                          {text}
                        </span>
                      )}
                    </FormattedMessage>
                    {messagesGroupPermissions.description && (
                      <FormattedMessage
                        {...messagesGroupPermissions.description}
                      >
                        {(text) => (
                          <span className={styles.permissionsDescription}>
                            {text}
                          </span>
                        )}
                      </FormattedMessage>
                    )}
                  </>
                </InfoBox>
              </div>

              <Groups
                resourceName={resourceName}
                resourceType={resourceType}
                scopes={scopes}
                groupsData={nonHabitatGroups}
                groupsLoading={groups?.loading}
                groupPermissions={groupPermissions}
                loadGroups={loadGroups}
                addGroupPermission={addGroupPermission}
                removeGroupPermission={removeGroupPermission}
              />
            </div>
          </Route>

          <Route path={`${baseUrl}/habitats`}>
            <div className={styles.groupPermissions}>
              <div className={styles.infoBoxParent}>
                <InfoBox>
                  <>
                    <FormattedMessage {...messagesGroupPermissions.subTitle}>
                      {(text) => (
                        <span className={styles.permissionsSubHeadline}>
                          {text}
                        </span>
                      )}
                    </FormattedMessage>
                    {messagesGroupPermissions.description && (
                      <FormattedMessage
                        {...messagesGroupPermissions.description}
                      >
                        {(text) => (
                          <span className={styles.permissionsDescription}>
                            {text}
                          </span>
                        )}
                      </FormattedMessage>
                    )}
                    <div className={styles.habitatPermissionInfo}>
                      <span>
                        In terms of permission management, each Habitat relates
                        to a dedicated Habitat Group. This has three
                        implications:
                      </span>
                      <ol>
                        <li>Permissions can be assigned to Habitat Groups.</li>
                        <li>
                          Users can be assigned to Habitat Groups and therefore
                          receive the permissions of the Habitat group.
                        </li>
                        <li>
                          Augurs and Code Capsules receive the permissions of
                          the Habitat they belong to. Therefore, the permissions
                          used for the execution of an Augur or a Code Capsule
                          are independent of the permissions of the User that
                          triggered the job.
                        </li>
                      </ol>
                    </div>
                  </>
                </InfoBox>
              </div>

              <Habitats
                resourceName={resourceName}
                resourceType={resourceType}
                scopes={scopes}
                groupsData={habitats}
                groupsLoading={groups?.loading}
                groupPermissions={groupPermissions}
                loadGroups={loadGroups}
                addGroupPermission={addGroupPermission}
                removeGroupPermission={removeGroupPermission}
              />
            </div>
          </Route>

          <Redirect to={`${baseUrl}/users${search}`} path={baseUrl} />
        </Switch>
      </div>
    </div>
  );
};

export default ResourcePermissions;

import { IconType } from 'react-icons';

import { AugurCategory } from '../../molecules/augur-menu/types';

// Maximum number of highlighted entries to display
export const MAX_HIGHLIGHTED_ENTRIES = 8;

export type TimeTravel = TimeTravelSelection & {
  updateSingleSelection: (singleSelection: string) => void;
  updateCompareSelection: (compareSelection: string[]) => void;
  onEntrySelected: (jobCode: string, selected: boolean) => void;
};

export type TimeTravelSelection = {
  singleSelection: string;
  compareSelection: string[];
};

export type TimeTravelHelpers = {
  isHighlighted: (
    code: string,
    type: AugurCategory,
    implicitSelection: string
  ) => boolean;
  isSelected: (code: string) => boolean;
};

export type TimeTravelEntry = {
  code: string;
  type: AugurCategory;
  modelCode: string;
  jobCode?: string;
  settingsCode?: string;
  timestamp: Date;
};

export type TimeTravelCategory = {
  Icon: IconType;
  name: string;
  placeholderName?: string;
};

export type TimelineEntry = {
  id: string;
  name: string;
  Icon: IconType;
  // used for the model timeline
  timestamp: Date;
  modelCode?: string;
  jobCode?: string;
  category: AugurCategory;

  // if undefined entry text is greyed out
  onSelected?: (selected: boolean) => void;
  // if true entry is bordered
  selected: boolean;
  // if true entry has background
  highlighted: boolean;
};
export interface TooltipState {
  type: 'job-code' | 'max-entries' | null;
  content: string;
  entryId: string | null;
}
export type EntryWithGridRow = TimelineEntry & {
  gridRow: number;
};
export type EntryWithPosition = EntryWithGridRow & {
  position: 'above' | 'below' | undefined;
};

export type TimelineEntryWithPosition = EntryWithGridRow | EntryWithPosition;

export type ModelTimelineElement = {
  modelCode: string;
  rowStart: number;
  rowEnd: number;
};

/** A datapool is the bucket associated with an augur */
export function datapoolName(augurCode: string): string {
  return 'augur-' + augurCode.toLowerCase();
}

/** Bucket names are only lowercase, so the augur code part is as well */
const datapoolBucketRegex = /augur-[0-9a-z]+/;

export const isDatapoolBucket = (bucketName: string): boolean =>
  datapoolBucketRegex.test(bucketName);

export const augurCodeFromBucket = (bucketName: string): string =>
  isDatapoolBucket(bucketName) ? bucketName.split('-')[1] : bucketName;

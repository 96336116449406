import { JOB_STATUS } from 'common/dist/constants/enums';
import {
  AugurJobsWithoutRealtime,
  isAugurJobWithoutRealtime,
} from 'common/dist/types/job';
import React, { FC } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

import { useSelectedAugurPage } from './hooks';
import styles from './styles.module.scss';
import { AugurSettingsWithAugurProperties, ModuleConfiguration } from './type';
import ViewAugur from './ViewAugur';
import {
  useAugur,
  useSettings,
  useUpdateAugur,
  useUpdateSettings,
} from '../../../core/api/augurs';
import {
  useInfiniteJobs,
  useLatestModelJob,
  useTimeTravelJobsSelection,
} from '../../../core/api/jobs';
import { useActiveModel } from '../../../core/api/mlModels';
import { useModules } from '../../../core/api/modules';
import { useReportsByCode } from '../../../core/api/reports';
import { event } from '../../../core/notifications';
import { sendNotification } from '../../../redux/modules/notifications.module';
import { useAppDispatch } from '../../../store/store';
import Busy from '../../atoms/busy/Busy';
import { AugurDetailsRouteParams } from '../../index/routes';
import { AugurCategory } from '../../molecules/augur-menu/types';
import OverlayAugurDetails from '../../organisms/sub-header/overlay-augur-details/OverlayAugurDetails.container';
import {
  getFirstActiveJobCodeOfCurrentCategory,
  getTimeTravelEntryFromJob,
} from '../../organisms/time-travel/helpers';
import { useTimeTravelHelpers } from '../../organisms/time-travel/hooks';
import MainContainer from '../main-container/MainContainer';
import NotFound from '../not-found/NotFound.container';

/**
 * Responsible for fetching jobs and filtering them for currently displayed reports.
 */
export const LiveAugur: FC = () => {
  const pathPrefix = useRouteMatch().url;
  const { augurCode } = useParams<AugurDetailsRouteParams>();

  // if the augur request succeeds, the augur exists
  const { isSuccess: augurExists, isInitialLoading: isAugurCheckLoading } =
    useAugur(augurCode);

  const { selectedPageCategory } = useSelectedAugurPage(pathPrefix);

  const { isHighlighted } = useTimeTravelHelpers(selectedPageCategory);

  const { data: activeModel, isLoading: isActiveModelLoading } = useActiveModel(
    augurCode,
    augurExists
  );
  const { data: jobsData, isLoading: isJobsLoading } = useInfiniteJobs(
    augurCode,
    JOB_STATUS.FINISHED,
    augurExists
  );
  const jobs = jobsData ? jobsData.pages.flatMap((page) => page.jobs) : [];

  // load latest job for active model in current category in case it was not loaded with sidebar
  const { data: latestModelJob } = useLatestModelJob(
    activeModel?.code,
    selectedPageCategory as AugurJobsWithoutRealtime,
    isAugurJobWithoutRealtime(selectedPageCategory) // only enabled if we are on a report page
  );
  // load selected jobs in case they were not loaded with sidebar
  const { data: selectedJobs } = useTimeTravelJobsSelection();
  // jobs that were loaded separately from the ones in view in TimeTravel
  const additionalJobs = [...(latestModelJob || []), ...(selectedJobs || [])];
  // add the additional jobs to the jobs data
  additionalJobs.forEach((additionalJob) => {
    if (!jobs.some((job) => job.code === additionalJob.code)) {
      jobs.push(additionalJob);
    }
  });

  if (!augurExists && !isAugurCheckLoading) {
    return <NotFound fullViewHeight />;
  }
  if (isAugurCheckLoading || isActiveModelLoading || isJobsLoading) {
    return <Busy isBusy={true} />;
  }

  // Handle case when there's no active model
  const hasNoModel = !activeModel?.code;

  // Only proceed with this calculation if there's an active model
  const firstActiveJobCodeOfCurrentCategory = hasNoModel
    ? null
    : getFirstActiveJobCodeOfCurrentCategory(
      jobs.map(getTimeTravelEntryFromJob),
      selectedPageCategory,
      activeModel.code
    )?.code;

  // calculate the jobs to be displayed from the TimeTravel selection
  // this uses the same logic that is used for calculating the highlighted entries of the TimeTravel component
  const displayedJobs = jobs.filter((job) =>
    isHighlighted(
      job.code,
      job.type as AugurCategory,
      firstActiveJobCodeOfCurrentCategory
    )
  );

  return (
    <MainContainer
      fullWidth
      transparent
      additionalInnerClassname={styles.augurDetailsInner}
    >
      <OverlayAugurDetails />
      <InnerLiveAugur
        displayedReportCodes={displayedJobs.map((job) => job.reportCode)}
        pathPrefix={pathPrefix}
        hasNoModel={hasNoModel}
      />
    </MainContainer>
  );
};

export type InnerProps = {
  displayedReportCodes: string[];
  pathPrefix: string;
  hasNoModel: boolean;
};

/**
 * Responsible for fetching the data for the ViewAugur.
 * @param displayedReportCodes
 * @param pathPrefix
 * @constructor
 */
export const InnerLiveAugur: FC<InnerProps> = ({
  displayedReportCodes,
  pathPrefix,
  hasNoModel,
}) => {
  const dispatch = useAppDispatch();
  const { augurCode } = useParams<AugurDetailsRouteParams>();

  const { data: augur, isLoading: isAugurLoading } = useAugur(augurCode);
  const { mutate: mutateAugur } = useUpdateAugur(augurCode);
  // separate hook because of loading animation
  const { mutate: mutateArchiveAugur, isLoading: isArchiveAugurLoading } =
    useUpdateAugur(augurCode);

  const { data: augurSettings, isLoading: isAugurSettingsLoading } =
    useSettings(augurCode);
  const { mutate: mutateAugurSettings } = useUpdateSettings(augurCode);
  const {
    data: augurReportsData,
    isInitialLoading: isAugurReportsLoading,
    // fetch status is necessary because of dependant query
    fetchStatus: augurReportsFetchStatus,
  } = useReportsByCode(displayedReportCodes);
  const augurReports = displayedReportCodes.length > 0 ? augurReportsData : [];

  const { data: modules, isInitialLoading: isModulesLoading } = useModules();

  const getModuleConfig = (moduleCode?: string, moduleVersionCode?: string) => {
    return modules
      ?.find((module) => module.code === moduleCode)
      ?.versions?.find((version) => version.code === moduleVersionCode)
      ?.config as unknown as ModuleConfiguration;
  };

  if (
    isAugurLoading ||
    isAugurSettingsLoading ||
    (isAugurReportsLoading && augurReportsFetchStatus === 'fetching') ||
    isModulesLoading
  )
    return <Busy isBusy={true} />;

  const displayedAugurSettings: AugurSettingsWithAugurProperties = {
    general: {
      augurName: augur.name,
      module: {
        moduleCode: augur.moduleVersion.module.code,
        moduleVersionCode: augur.moduleVersion.code,
      },
      attributes: augur.attributes,
      resources: augurSettings.resources,
    },
    settingsData: augurSettings.settingsData,
  };

  // @ts-expect-error because the frontend type is more complex and uses frontend specific types, typescript isn't quite happy here
  const config: ModuleConfiguration = augur.moduleVersion.config;

  return (
    <ViewAugur
      moduleConfig={config}
      getModuleConfig={getModuleConfig}
      augurReports={augurReports}
      augurSettings={displayedAugurSettings}
      pathPrefix={pathPrefix}
      isDevMode={false}
      onSubmitAugurSettings={(settings) => {
        const newModuleVersion = settings.general.module.moduleVersionCode;
        mutateAugur({
          name: settings.general.augurName,
          attributes: settings.general.attributes,
        });
        mutateAugurSettings({
          settingsData: settings.settingsData,
          resources: settings.general.resources,
          moduleVersionCode: newModuleVersion,
          merge: false,
        });
      }}
      onArchiveAugur={(archive) => {
        mutateArchiveAugur(
          {
            archived: archive,
          },
          {
            onSuccess: () => {
              dispatch(
                sendNotification(
                  archive
                    ? 'notifications.title.augur_archived'
                    : 'notifications.title.augur_restored',
                  // @ts-ignore
                  archive
                    ? 'notifications.description.augur_has_been_archived'
                    : 'notifications.description.augur_has_been_restored',
                  event,
                  { augurCode, augurName: augur.name }
                )
              );
            },
          }
        );
      }}
      augurArchived={augur.archived}
      isArchiveButtonLoading={isArchiveAugurLoading}
      hasNoModel={hasNoModel}
    />
  );
};

export default LiveAugur;

export const comparators = {
  '1a': (a, b) => (b.health ?? 0) - (a.health ?? 0),
  '1b': (a, b) => (a.health ?? 0) - (b.health ?? 0),

  '2a': (a, b) => a.name.localeCompare(b.name),
  '2b': (a, b) => b.name.localeCompare(a.name),

  '3a': (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  '3b': (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
};

export const defaultOrdering = '2a';
export const defaultComparator = comparators[defaultOrdering];

export function composeHabitats(habitats, summary) {
  const newHabitats = {};

  summary.forEach((habitat) => {
    if (!habitat.augurs) {
      habitat.augurs = []; // eslint-disable-line no-param-reassign
    }

    const order = habitats[habitat.code] && habitats[habitat.code].order;

    newHabitats[habitat.code] = {
      isListView: false,
      order: order || {
        value: defaultOrdering,
      },
      augurs: habitat.augurs,
      amountArchivedAugurs: habitat.amountArchivedAugurs,
      archived: [],
    };
  });

  return newHabitats;
}

export function setHabitatView(state, { habitatCode, isListView }) {
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      habitats: {
        ...state.dashboard.habitats,
        [habitatCode]: {
          ...state.dashboard.habitats[habitatCode],
          isListView,
        },
      },
    },
  };
}

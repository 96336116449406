import { NotificationType } from 'common/dist/types/notification';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { Notification } from './Notification';
import { RootState } from '../../store/store';

export const Notifications: FC = () => {
  const notifications: NotificationType[] = useSelector(
    (state: RootState) => state.notifications
  );
  return (
    <div
      data-testid={'notification'}
      className='notifications'
      style={{ bottom: 0 /* 80 * (notifications.length - 1)*/ }}
    >
      {notifications.map((notification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
    </div>
  );
};

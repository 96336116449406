import React, { FC } from 'react';
import { useContent } from '../../../../core/api/workbench/content';
import { JupyterContentElement } from '../../../../store/workbench/state.types';
import Paging from '../../../molecules/paging/Paging';
import styles from './styles.module.scss';
import Busy from '../../../atoms/busy/Busy';
import ProjectCard from '../../../molecules/project-card/ProjectCard';
import InputError from '../../../atoms/input-error/InputError';

const ProjectsOverview: FC = () => {
  // Get data from top level directory of cloned projects (empty path)
  const { data, error, isLoading } = useContent('');
  const content = (data?.content as JupyterContentElement[]) ?? [];
  const projects = content
    .filter((entry) => entry.name.endsWith('.asr'))
    .sort((a, b) => a.name.localeCompare(b.name));

  if (isLoading) {
    return <Busy isBusy={true} />;
  }
  if (error) {
    return <InputError touched={true} error={error} />;
  }

  return (
    <div className={'CollaborationSpace--content'}>
      <Paging itemsPerPage={20} currentItems={projects.length} searchEnabled>
        <div className={styles.projectCardGrid}>
          {projects.map((project, index) => (
            <ProjectCard {...project} key={index} />
          ))}
        </div>
      </Paging>
    </div>
  );
};

export default ProjectsOverview;

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { CodeCapsule } from 'common/dist/types/codeCapsule';
import { NamesResponseBody } from 'common/dist/types/responseBodies/base';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { ResourceNames } from 'common/dist/types/utils';
import qs from 'qs';

import {
  apiRequest,
  CompletedApiRequest,
  fetchQueryFn,
  postApiRequest,
} from './_tools';

export const codeCapsuleKeys = {
  all: () => ['codecapsules'] as const,
  codeCapsule: (codeCapsuleCode: string) =>
    [...codeCapsuleKeys.all(), codeCapsuleCode] as const,
  codeCapsules: (affiliationType?: string, habitatCode?: string) =>
    [...codeCapsuleKeys.all(), affiliationType, habitatCode] as const,
  names: (habitatCode?: string) =>
    [...codeCapsuleKeys.all(), 'names', habitatCode] as const,
};

export function fetchCodeCapsules(affiliationType, habitatCode) {
  return apiRequest(
    `/api/codecapsules?affiliationType=${affiliationType}&habitatCode=${habitatCode}`
  );
}

export function getCodeCapsules(
  affiliationType?: string,
  habitatCode?: string
): CompletedApiRequest<CodeCapsule[]> {
  const query = qs.stringify(
    { affiliationType, habitatCode },
    { addQueryPrefix: true }
  );
  return apiRequest(`/api/codecapsules${query}`);
}

export function useCodeCapsules(
  affiliationType?: string,
  habitatCode?: string
): UseQueryResult<CodeCapsule[]> {
  const key = codeCapsuleKeys.codeCapsules(affiliationType, habitatCode);
  return useQuery(key, () =>
    fetchQueryFn(key, () => getCodeCapsules(affiliationType, habitatCode))
  );
}

export function getCodeCapsuleNames(
  habitatCode?: string
): CompletedApiRequest<NamesResponseBody> {
  const query = qs.stringify({ habitatCode }, { addQueryPrefix: true });
  return apiRequest(`/api/codecapsules/names${query}`);
}

export function useCodeCapsuleNames(
  habitatCode?: string,
  enabled = true
): UseQueryResult<ResourceNames> {
  const key = codeCapsuleKeys.names(habitatCode);
  return useQuery(
    key,
    async () => fetchQueryFn(key, () => getCodeCapsuleNames(habitatCode)),
    { enabled }
  );
}

export function fetchSingleCodeCapsule(codeCapsuleCode) {
  return apiRequest(`/api/codecapsules/${codeCapsuleCode}`);
}

export function getCodeCapsule(
  codeCapsuleCode: string
): CompletedApiRequest<CodeCapsule> {
  return apiRequest(`/api/codecapsules/${codeCapsuleCode}`);
}

export function useCodeCapsule(
  codeCapsuleCode: string
): UseQueryResult<CodeCapsule> {
  const key = codeCapsuleKeys.codeCapsule(codeCapsuleCode);
  return useQuery(key, () =>
    fetchQueryFn(key, () => getCodeCapsule(codeCapsuleCode))
  );
}

export function fetchCodeCapsuleExecutions(codeCapsuleCode) {
  return apiRequest(`/api/codecapsules/${codeCapsuleCode}/executions`);
}

export function fetchCodeCapsuleReport(jobCode) {
  return apiRequest(`/api/codecapsules/report/${jobCode}`);
}

export function fetchCodeCapsuleOutput(jobCode, notebookName) {
  return apiRequest(
    `/api/codecapsules/output/${jobCode}/notebook/${notebookName}`
  );
}

export function triggerRun(
  repositoryName,
  repositoryCode,
  capsuleVersionNumber,
  capsuleImage,
  codeCapsuleCode,
  notebooksToExecute,
  resources,
  parameters,
  habitatCode,
  requestCredentialsFor,
  name,
  gpu,
  priority
) {
  const body = {
    repositoryCode,
    codeCapsuleCode,
    capsuleVersionNumber,
    notebooksToExecute,
    parameters,
    resources,
    capsuleImage,
    habitatCode,
    requestCredentialsFor,
    repositoryName, // Not read
    name,
    gpu: gpu,
    priority,
  };
  return postApiRequest('/api/codecapsules/triggerrun', body);
}

export type NodeInfo = {
  nodes: {
    name: string;
    resources: {
      cpu: {
        capacity: number;
        allocatable: number;
      };
      memory: {
        capacity: number;
        allocatable: number;
      };
      gpu: {
        capacity: number;
        allocatable: number;
        driverVersion: string;
        runtimeVersion: string;
        computeVersion: string;
        family: string;
        memoryMb: number;
        model: string;
      };
    };
  }[];
};

export function fetchNodeInfo(): CompletedApiRequest<NodeInfo> {
  return apiRequest(`/orchestration/node-info`);
}

export function useNodeInfo(): UseQueryResult<NodeInfo, ToBeRefined> {
  return useQuery(['node-info'], async () => {
    const res = await fetchNodeInfo();
    if (!res.response) throw Error(`Unable to fetch node-info: ${res.error}.`);
    return res.response;
  });
}

import GroupRepresentation from '@keycloak/keycloak-admin-client/lib/defs/groupRepresentation';
import classNames from 'classnames';
import React, { Component } from 'react';

import styles from './styles.module.scss';
import { fetchGroupPermissions } from '../../../../redux/modules/admin.users.module';
import LoadingPlaceholder from '../../../atoms/loading-placeholder/LoadingPlaceholder';
import ThemedSwitch from '../../../atoms/themed-switch/ThemedSwitch';

type Props = {
  /** Name of the resource to list the group permissions for */
  resourceName: string;
  /** List of available scopes */
  scopes: {
    id: string;
    displayName: string;
  }[];
  /** Resource type */
  resourceType: string;

  /* --- Groups --- */
  groupsData: GroupRepresentation[];
  groupsLoading?: boolean;
  /** Trigger loading the groups */
  loadGroups: (fetchPermissions: boolean) => void;

  /* --- Permissions */
  /** Add a group-wise permission */
  addGroupPermission: (
    resourceName: string,
    resourceType: string,
    scopeName: string,
    group,
    refreshAction: () => void
  ) => void;
  /** Remove a group-wise permission */
  removeGroupPermission: (
    resourceName: string,
    scopeName: string,
    group,
    refreshAction: () => void
  ) => void;
  groupPermissions?: {
    [groupId: string]: {
      loading?: boolean;
      loaded?: boolean;
      error?: string;
      data?: {
        [resourceName: string]: {
          name: string;
        }[];
      };
    };
  };
};
interface State {
  loadingStates: Record<string, boolean>;
}
export default class Groups extends Component<Props, State> {
  static defaultProps = {
    scopesView: true,
    scopesEdit: true,
  };

  state = {
    loadingStates: {},
  };

  componentDidMount() {
    const { loadGroups } = this.props;
    loadGroups(true);
  }

  hasGroupPermission(groupId, scopeName) {
    const { groupPermissions, resourceName } = this.props;
    if (!groupPermissions) return false;

    return ((groupPermissions[groupId]?.data || [])[resourceName] || [])
      .map((e) => e.name)
      .includes(scopeName);
  }

  setLoading = (groupId: string, scopeId: string, isLoading: boolean) => {
    this.setState((prevState) => ({
      loadingStates: {
        ...prevState.loadingStates,
        [`${groupId}_${scopeId}`]: isLoading,
      },
    }));
  };

  render() {
    const {
      groupsData,
      groupsLoading,
      resourceName,
      resourceType,
      addGroupPermission,
      removeGroupPermission,
      scopes,
      groupPermissions,
    } = this.props;

    const { loadingStates } = this.state;

    const renderHeadline = () => {
      return (
        <div className={'ct-row ct-headline'}>
          <div className={'ct-col ct-col-flex-grow'}>
            <span>Group Name</span>
          </div>
          {scopes.map((scope) => (
            <div className={'ct-col ct-col-80px'}>
              <span>{scope.displayName}</span>
            </div>
          ))}
        </div>
      );
    };

    if ((!groupsData || groupsData.length === 0) && groupsLoading) {
      return (
        <div className={'groups-permissions-container ct-list'}>
          {renderHeadline()}
          {Array.from({ length: 10 }).map((_, i) => (
            <div className={'ct-row'}>
              <div className={'ct-col ct-col-200px'}>
                <LoadingPlaceholder>Loading Placeholder</LoadingPlaceholder>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className={'groups-permissions-container ct-list'}>
        {renderHeadline()}
        {groupsData
          ?.sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((group) => (
            <div
              key={group.id}
              className={classNames('ct-row', styles.permissionsRow)}
              data-testid={group?.name}
            >
              <div className={'ct-col ct-col-flex-grow-200px'}>
                <span>{group.name}</span>
              </div>

              {scopes.map((scope) => (
                <div
                  key={`${group.id}_${scope.id}`}
                  className={'ct-col ct-col-80px'}
                >
                  <ThemedSwitch
                    onChange={() => {
                      this.setLoading(group.id, scope.id, true);
                      if (this.hasGroupPermission(group.id, scope.id)) {
                        removeGroupPermission(
                          resourceName,
                          scope.id,
                          group,
                          () => {
                            this.setLoading(group.id, scope.id, false);
                            return fetchGroupPermissions(group.id);
                          }
                        );
                      } else {
                        addGroupPermission(
                          resourceName,
                          resourceType,
                          scope.id,
                          group,
                          () => {
                            this.setLoading(group.id, scope.id, false);
                            return fetchGroupPermissions(group.id);
                          }
                        );
                      }
                    }}
                    checked={this.hasGroupPermission(group.id, scope.id)}
                    height={20}
                    width={40}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                    activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                    themeColor={'primary'}
                    isLoading={
                      loadingStates[`${group.id}_${scope.id}`] ||
                      groupPermissions?.[group.id]?.loading
                    }
                  />
                </div>
              ))}
            </div>
          ))}
      </div>
    );
  }
}

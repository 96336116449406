// Mainly used to add the Datapool name to the Jobs - TODO:NOW so can it be removed?
import { NamesResponseBody } from 'common/dist/types/responseBodies/base';
import { apiRequest, CompletedApiRequest, fetchQueryFn } from './_tools';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ResourceNames } from 'common/dist/types/utils';

export const plainRepositoryKeys = {
  all: () => ['repositories'] as const,
  names: () => [...plainRepositoryKeys.all(), 'names'] as const,
  slugs: () => [...plainRepositoryKeys.all(), 'slugs'] as const,
};

export function getRepositoryName(repositoryCode) {
  return apiRequest(`/api/workbench/collab/gitrepo/${repositoryCode}/name`);
}

export function getPlainRepositoriesNames(): CompletedApiRequest<NamesResponseBody> {
  return apiRequest('/api/plainrepositories/names');
}

export function usePlainRepositoriesNames(
  enabled = true
): UseQueryResult<ResourceNames> {
  const key = plainRepositoryKeys.names();
  return useQuery(
    key, 
    async () => fetchQueryFn(key, () => getPlainRepositoriesNames()),
    { enabled }
  );
}

export function getRepositorySlugs(): CompletedApiRequest<NamesResponseBody> {
  return apiRequest('/api/repositories/slugs');
}

export function useRepositorySlugs(): UseQueryResult<ResourceNames> {
  const key = plainRepositoryKeys.slugs();
  return useQuery(key, async () => {
    return fetchQueryFn(key, () => getRepositorySlugs());
  });
}

import React, { FC } from 'react';
import { WrappedFieldProps } from 'redux-form';

import './antstyles.scss';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import {
  SelectedSchedule as ScheduleType,
  SelectedSchedule,
} from 'common/src/utils/schedules';
import dayjs from 'dayjs';

export type Props = {
  untouch: () => void;
  validateField: (value: SelectedSchedule) => {};
};

const DelayedScheduleSelect: FC<WrappedFieldProps & Props> = (props) => {
  const {
    input: { value, onChange },
    validateField,
  } = props;

  // Reset validation if value is not set
  if (!value.trigDelayed) {
    validateField(value as SelectedSchedule);
  }

  // Function for datetimepicker to disable days in the past and days after year 2100
  const disableDate: RangePickerProps['disabledDate'] = (current) => {
    return (
      current < dayjs().subtract(1, 'day') || current > dayjs().add(100, 'year')
    );
  };

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  // Function for datetimepicker to disable hours and minutes in the past of current day
  const disabledDateTime = (current) => {
    if (dayjs(current).day() === undefined) {
      return {
        disabledHours: () => range(0, 24),
        disabledMinutes: () => range(0, 60),
        disabledSeconds: () => [],
      };
    }
    if (dayjs(current).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) {
      return {
        disabledHours: () => range(0, dayjs().hour()),
        disabledMinutes:
          dayjs(current).hour() === dayjs().hour()
            ? () => range(0, dayjs().minute() + 1)
            : () => [],
        disabledSeconds: () => [],
      };
    }
  };

  // Updates redux-form with selected date from datetimepicker
  const handleSelectDate = (datetime) => {
    const updatedValue: ScheduleType = {
      trigger: value.trigger,
      trigDelayed: datetime
        ? dayjs(datetime).format('YYYY-MM-DD HH:mm')
        : undefined,
    };
    onChange(updatedValue);
  };

  return (
    <DatePicker
      value={value.trigDelayed ? dayjs(value.trigDelayed) : undefined}
      showNow={false}
      disabledTime={disabledDateTime}
      disabledDate={disableDate}
      format='YYYY-MM-DD HH:mm'
      showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }}
      size='large'
      onChange={handleSelectDate}
      // @ts-ignore
      onSelect={handleSelectDate}
    />
  );
};

export default DelayedScheduleSelect;

import { ValueType } from '../../../../organisms/cassandra-table-preview/CassandraTablePreview';
import { Props } from './WizardCommit';
import { ConfigProps, FormErrors } from 'redux-form';
import { CommitColSpec } from '../../../../../store/dataManagement/state.types';
import { ColSpec } from 'common/dist/types/dataManagement/cassandra';

export const formName = 'commit-wizard';

export const fieldKeys = 'keys';
export const fieldData = 'data';

export interface FormData {
  [fieldData]: ValueType;
  [fieldKeys]: {
    /** Indices of columns in colSpec returned by backend (allow for duplicate column names) */
    clustering: string[];
    partition: string[];
  };
}

/**
 * Derives the column specs for the commit call in the form of [{"newName": ..., "oldName": ..., "type": ...}, ...]
 * Also called for validation
 * @param colSpecs
 * @param formValues
 */
export function getCommitColSpecs(
  colSpecs: ColSpec[],
  formValues: ValueType
): CommitColSpec[] {
  // Must be the same index as used for the id in the table (order from the commitInfo dataPreview.colSpecs)
  return colSpecs.map((col, i) => {
    const overwriteCol = formValues?.[i];
    return {
      newName: overwriteCol?.newName ?? col.colName,
      oldName: col.colName,
      newType: overwriteCol?.newType ?? col.colType,
    };
  });
}

/**
 * @param values
 * @param data - the commitInfo data from the state **NOT** the field also called data
 */
export function validate(
  values: FormData,
  { data }: Props
): FormErrors<FormData> {
  const errors = {};

  /** KEYS **/
  const keys = values[fieldKeys];
  // The only reason for the if / else split is code readability. Both clauses do the same semantic thing:
  // Checking whether there is at least one partition or clustering key.
  if (!keys) {
    // Nothing selected at all (not even touched the field)
    errors[fieldKeys] = 'Please select at least one partition key column';
  } else {
    // The field was at least touched, but validate if there's at least one clustering or partition key
    const noPartition = !keys.partition || keys.partition.length === 0;
    if (noPartition) {
      errors[fieldKeys] = 'Please select at least one partition key column';
    }
  }

  /** DATA **/
  if (data) {
    const {
      dataPreview: { colSpecs },
    } = data;
    const formData = values[fieldData];
    const newColSpecs = getCommitColSpecs(colSpecs, formData);
    const missingNameColSpec = newColSpecs.find((cs) => cs.newName === '');
    // At the moment only one error is returned. If there are more they can be fixed step by step.
    if (missingNameColSpec) {
      errors[
        fieldData
      ] = `Missing column name for the previously named column "${missingNameColSpec.oldName}"`;
    } else {
      const newNamesSorted = newColSpecs.map((cs) => cs.newName).sort();
      const duplicateNames = new Set();
      for (let i = 0; i < newNamesSorted.length - 1; i++) {
        if (newNamesSorted[i] === newNamesSorted[i + 1]) {
          duplicateNames.add(newNamesSorted[i]);
        }
      }
      if (duplicateNames.size > 0) {
        errors[fieldData] = `Duplicate column names: ${Array.from(
          duplicateNames
        ).join(', ')}`;
      }
    }
  }

  return errors;
}

/**
 * redux-form defaultShouldError function https://github.com/redux-form/redux-form/blob/v8.3.7/src/defaultShouldError.js
 */
const defaultShouldError = ({
  values,
  nextProps,
  // props,  // not used in default implementation
  initialRender,
  lastFieldValidatorKeys,
  fieldValidatorKeys,
  structure,
}): boolean => {
  if (initialRender) {
    return true;
  }
  return (
    !structure.deepEqual(values, nextProps && nextProps.values) ||
    !structure.deepEqual(lastFieldValidatorKeys, fieldValidatorKeys)
  );
};

export const commitForm: ConfigProps<FormData, Props> = {
  form: formName,
  validate,
  // We want to extend it here to also run validation when the data field from the props changes (because the user uploaded a table with duplicate columns for example)
  // The alternative of touching the fieldData when the data arrives, did not successfully trigger a new validation
  shouldError(params): boolean {
    if (params.initialRender) {
      return true;
    }
    if (params.props.data !== params.nextProps.data) {
      return true;
    } else return defaultShouldError(params);
  },
  destroyOnUnmount: true,
};

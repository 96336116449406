import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AdminUserDetails, { Props } from './AdminUserDetails';
import {
  addRealmRoleMapping,
  adminLoadUserDetails,
  joinGroup,
  leaveGroup,
  loadGroups,
  loadRoles,
  removeRealmRoleMapping,
} from '../../../../redux/modules/admin.users.module';
import { DeprecatedRootState } from '../../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState, props: Props) {
  const { userId } = props.match.params;
  const user = state.admin.userDetails.user || {}; // TODO type the admin.userDetails state

  return {
    userId,
    user,
    roles: state.admin.roles,
    groups: state.admin.groups,
  };
}

export const mapDispatchToProps = {
  adminLoadUserDetails,
  loadRoles,
  loadGroups,
  addRealmRoleMapping,
  removeRealmRoleMapping,
  joinGroup,
  leaveGroup,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminUserDetails)
);

import React, { FC, Fragment, MouseEvent } from 'react';
import { WrappedFieldProps } from 'redux-form';

import styles from './styles.module.scss';
import { useAugurNames } from '../../../../../../core/api/augurs';
import { useCodeCapsuleNames } from '../../../../../../core/api/codeCapsules';
import Button from '../../../../../atoms/button/Button';
import JobGroupTopologyChart from '../../../../../molecules/job-groups/job-group-topology-chart/JobGroupTopologyChart';
import { JobGroup } from '../../newSchedule.form';

/**
 * StepComponent for inside a GenericFormStep, which shows the current edited job group and can open an editor with passed
 * function
 * @param props - props.onClick: What function/action to call, when clicking the surrounding div (which includes the Open Editor button)
 * @constructor
 */
export const StepComponent: FC<
  { onClick: (e: MouseEvent) => void } & WrappedFieldProps
> = (props) => {
  const { onClick, input } = props;
  const value: JobGroup = input.value;

  const { data: augurNames } = useAugurNames();
  const { data: codeCapsuleNames } = useCodeCapsuleNames();

  return (
    <div className={styles.JobGroupStep}>
      <div className={styles.topology} onClick={onClick}>
        {(value?.jobs || []).length > 0 ? (
          <Fragment>
            <JobGroupTopologyChart
              jobs={value.jobs || []}
              jobGroupTopology={value.jobGroupTopology || []}
              augurNames={augurNames || {}}
              codeCapsuleNames={codeCapsuleNames || {}}
              showJobStatus={false}
              slim={true}
            />
            <span className={styles.editorHint}>Click to open the Editor</span>
          </Fragment>
        ) : (
          <div className={styles.emptyContainer}>
            <Button label={'Open Editor'} color={'secondary'} />
          </div>
        )}
      </div>
    </div>
  );
};

import { augurCodeFromBucket } from 'common/dist/constants/dataManagement/s3';
import { DSType } from 'common/dist/types/dataManagement/dataSource';
import { Bucket } from 'common/dist/types/dataManagement/s3';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAugurNames } from '../../../../../core/api/augurs';
import { fetchBuckets } from '../../../../../redux/modules/data.s3.module';
import { RootState } from '../../../../../store/store';
import ResourceList from '../../../../organisms/permissions/resouce-list/ResourceList';
import { bucketOrKeyspaceRoute } from '../DataSourcePermissions';

type Props = {};

const S3Permissions: FC<Props> = () => {
  const { dataSourceCode } = useParams<{
    dataSourceCode: string;
  }>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBuckets(dataSourceCode));
  }, [dataSourceCode, dispatch]);

  const buckets = useSelector<RootState, Bucket[]>(
    (state) => state.data.s3[dataSourceCode]?.buckets?.data
  );

  const bucketsLoading = useSelector<RootState, boolean>(
    (state) => state.data.s3[dataSourceCode]?.buckets?.loading
  );

  const { data: augurNames } = useAugurNames();

  return (
    <ResourceList
      headlines={[
        {
          headlineId: 'bucketName',
          label: {
            id: 'no-id',
            defaultMessage: 'Bucket Name',
          },
        },
        {
          headlineId: 'augurName',
          label: {
            id: 'no-id',
            defaultMessage: 'Augur Name',
          },
        },
      ]}
      entries={(buckets || []).map((bucket) => {
        const speakingName: string | undefined =
          augurNames?.[augurCodeFromBucket(bucket.name).toUpperCase()];
        return {
          linkTo: bucketOrKeyspaceRoute(DSType.S3, dataSourceCode, bucket.name),
          columnValues: {
            bucketName: bucket.name,
            augurName: speakingName,
          },
        };
      })}
      // Show the loading entries, but only if the data hasn't been loaded yet. Otherwise the loading screen always flashes even though data was already loaded (and is only re-loaded in the background)
      isLoading={(!buckets || buckets.length === 0) && bucketsLoading}
    />
  );
};

export default S3Permissions;

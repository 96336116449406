import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AugurReport } from 'common/dist/types/reports';
import qs from 'qs';

import { apiRequest, CompletedApiRequest, fetchQueryFn } from './_tools';

export const reportKeys = {
  all: () => ['reports'] as const,
  reportsByCode: (reportCodes: string[]) =>
    [...reportKeys.all(), reportCodes] as const,
} as const;

export const getReportsByCode = (
  reportCodes: string[]
): CompletedApiRequest<AugurReport[]> => {
  const query = qs.stringify(
    { reportCodes },
    { addQueryPrefix: true, arrayFormat: 'comma' }
  );
  return apiRequest(`/api/reports${query}`);
};

export const useReportsByCode = (
  reportCodes: string[]
): UseQueryResult<AugurReport[]> => {
  const key = reportKeys.reportsByCode(reportCodes);
  return useQuery(
    key,
    () => fetchQueryFn(key, () => getReportsByCode(reportCodes)),
    {
      keepPreviousData: true,
      enabled: reportCodes.length > 0,
    }
  );
};

import commonMessages from 'common/dist/messages/common';
import errorMessages from 'common/dist/messages/error';
import orchestrationMessages from 'common/dist/messages/orchestration';
import qs, { ParsedQs } from 'qs';
import React, { FC, useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import {
  deleteJobGroupThenFetch,
  fetchJobGroups,
  JobGroupsState,
  runJobGroup,
} from '../../../store/jobGroups/slice';
import { RootState, useAppDispatch } from '../../../store/store';
import Busy from '../../atoms/busy/Busy';
import Button from '../../atoms/button/Button';
import IndicatorEmpty from '../../molecules/indicator-empty/IndicatorEmpty';
import JobGroup from '../../molecules/job-groups/job-group/JobGroup';
import GenericPagingHeader from '../../molecules/paging/GenericPagingHeader';
import Paging, {
  PagingParams,
  usePagingParameters,
} from '../../molecules/paging/Paging';
import ConfirmationModal from '../../organisms/confirmation-modal/ConfirmationModal';
import ErrorBoundary from '../../pages/error-boundary/ErrorBoundary';
import { orchestrationRoutes } from '../routes';

export interface ScheduleParams extends ParsedQs {
  name?: string;
  description?: string;
  jobGroupCode?: string;
}
export interface Props {}

const PAGE_SIZE = 10;

const Loaded: FC<JobGroupsState & RouteComponentProps> = (props) => {
  const { data, location } = props;
  const [showModal, setShowModal] = useState<{
    show: boolean;
    jobGroupCode?: string;
  }>({
    show: false,
    jobGroupCode: undefined,
  });
  const appDispatch = useAppDispatch();

  const queryParameter: PagingParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { offset, limit, search } = queryParameter;
  const offsetInt = Number.isInteger(Number(offset)) ? Number(offset) : 0;
  const limitInt = Number.isInteger(Number(limit)) ? Number(limit) : PAGE_SIZE;

  return (
    <>
      <div className={'JobGroups--Header'} data-testid={'JobGroupsHeader'}>
        <strong>Job Group</strong>
        <strong>Name</strong>
        <strong>By</strong>
        <strong>Actions</strong>
      </div>
      {data.map((jg) => {
        const qs_jobGroup: ScheduleParams = {
          name: jg.name,
          description: jg.description,
          jobGroupCode: jg.code,
        };
        return (
          <JobGroup
            key={jg.code}
            jobGroupCode={jg.code}
            jobGroup={jg}
            deleteAction={() =>
              setShowModal({ show: true, jobGroupCode: jg.code })
            }
            runAction={() => {
              // @ts-ignore
              appDispatch(runJobGroup({ jobGroup: jg }));
            }}
            editLink={`${orchestrationRoutes.basePath}/${orchestrationRoutes.editJobGroup.path}/${jg.code}`}
            createScheduleFromJobGroupLink={`${orchestrationRoutes.basePath}/${
              orchestrationRoutes.addSchedule.path
            }${qs.stringify(qs_jobGroup, { addQueryPrefix: true })}`}
          />
        );
      })}
      <ConfirmationModal
        show={showModal.show}
        secure={false}
        payload={showModal.jobGroupCode}
        hideModal={() => setShowModal({ show: false, jobGroupCode: undefined })}
        buttonConfirmAction={(jobGroupCode: string) => {
          appDispatch(
            deleteJobGroupThenFetch({
              jobGroupCode: jobGroupCode,
              offset: offsetInt,
              limit: limitInt,
              search: search,
            })
          );
        }}
        buttonConfirmText={commonMessages.delete}
        buttonConfirmColor={'red'}
        description={orchestrationMessages.jobGroupDeleteModalDescription}
        headline={orchestrationMessages.jobGroupDeleteModalHeadline}
        headlineColor={'red'}
      />
    </>
  );
};

const InnerComponent: FC<JobGroupsState & RouteComponentProps> = (props) => {
  const { loading, data, error } = props;

  if (loading) return <Busy isBusy positionAbsolute />;
  else if (data && data.length === 0)
    return (
      <IndicatorEmpty
        classNameImage={'orchestration-history-empty-pic'}
        headlineId={'todo'}
        headlineDefault={'There are no Job Groups yet'}
        descriptionId={'todo'}
        descriptionDefault={
          'Once a Job Group has been added, it will be shown here'
        }
      />
    );
  else if (error) return <span>Error: {JSON.stringify(error)}</span>;

  return <Loaded {...props} />;
};

function renderHeader(offset: number, currentPageAmount: number) {
  return (
    <div className={'JobGroups--Header-paging'}>
      {
        <div data-testid={'JobGroupCounter'}>
          <GenericPagingHeader
            offset={offset}
            currentItems={currentPageAmount}
            itemLabel={'Job Groups'}
          />
        </div>
      }
      <div className={'JobGroups--Button'}>
        <Button
          data-testid={'Add Job Group'}
          linkTo={`${orchestrationRoutes.basePath}/${orchestrationRoutes.addJobGroup.path}`}
          color={'secondary'}
          label={'Add Job Group'}
          Icon={() => <FiPlus size={16} />}
        />
      </div>
    </div>
  );
}

const JobGroups: FC<Props & RouteComponentProps> = (props) => {
  const { loading, loaded, data, error } = useSelector<
    RootState,
    JobGroupsState
  >((state) => state.jobGroups);
  const appDispatch = useAppDispatch();

  const { offset, search } = usePagingParameters();
  useEffect(() => {
    appDispatch(
      fetchJobGroups({
        offset,
        limit: PAGE_SIZE,
        search,
      })
    );
  }, [appDispatch, offset, search]);

  return (
    <ErrorBoundary
      errorHeadline={errorMessages.headline}
      errorDescription={errorMessages.description}
    >
      <div className='Orchestration--content'>
        <div className={'Orchestration--inner-content not-scrollable'}>
          <Paging
            itemsPerPage={PAGE_SIZE}
            currentItems={data.length}
            searchEnabled
            Headline={() => renderHeader(offset, (data || []).length)}
          >
            <InnerComponent
              loading={loading}
              loaded={loaded}
              data={data}
              error={error}
              {...props}
            />
          </Paging>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default withRouter(JobGroups);

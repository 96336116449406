// Mainly used to add the Datapool name to the Jobs - TODO:NOW so can it be removed?
import { NamesResponseBody } from 'common/dist/types/responseBodies/base';
import { apiRequest, CompletedApiRequest, fetchQueryFn } from './_tools';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ResourceNames } from 'common/dist/types/utils';

export const appsKeys = {
  all: () => ['apps'] as const,
  names: () =>
    [...appsKeys.all(), 'names'] as const,
};

export function fetchApps(onlyWithAtLeastOneVersion, admin = false) {
  return apiRequest(
    `/api${admin ? '/admin' : ''
    }/apps?onlyWithAtLeastOneVersion=${onlyWithAtLeastOneVersion}`
  );
}

export function fetchAppVersions(appCode) {
  return apiRequest(`/api/apps/${appCode}/versions`);
}

export function getAppsNames(): CompletedApiRequest<NamesResponseBody> {
  return apiRequest(`/api/apps/names`);
}

export function useAppsNames(
  enabled = true
): UseQueryResult<ResourceNames> {
  const key = appsKeys.names();
  return useQuery(
    key,
    async () => fetchQueryFn(key, () => getAppsNames()),
    { enabled }
  );
}
import classNames from 'classnames';
import { Job as JobT, JobGroupTopologyType } from 'common/dist/types/job';
import { ResourceNames } from 'common/dist/types/utils';
import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  Edge,
  Elements,
  isNode,
  Node,
  ReactFlowProvider,
  ReactFlowState,
  useStoreState,
} from 'react-flow-renderer';

import { getLayoutedElements } from './graphLayout';
import './styles.scss';
import { useProgressSummary } from '../../../../core/api/orchestration';
import Tooltip from '../../../atoms/tooltip/Tooltip';
import Job, { nodeTypes, Props as JobProps } from '../job/Job';
import jobStyles from '../job/styles.module.scss';

export interface Props {
  jobs: JobT[];
  jobGroupTopology: JobGroupTopologyType[];
  augurNames: ResourceNames;
  codeCapsuleNames: ResourceNames;
  /** Should the status (for example 'Waiting') be shown in the Job? */
  showJobStatus: boolean;
  /** For example if the job details are supposed to be shown on click */
  onClick?: (jobCode: string) => void;
  /** Render the jobs in the slim variant */
  slim?: boolean;
}

const JobGroupTopologyChart: FC<Props> = (props) => {
  const {
    jobs,
    augurNames,
    codeCapsuleNames,
    jobGroupTopology,
    showJobStatus,
    onClick,
    slim,
  } = props;
  // stores the node the cursor is currently hovering above
  const [hoverNode, setHoverNode] = useState<Node<JobProps>>();

  const progressQuery = useProgressSummary();

  // Build the nodes and edges from jobs and jobGroupTopology, use memo for optimization
  const nodesMemo: Node<JobProps>[] = useMemo(
    () =>
      jobs.map((job, index) => {
        return {
          id: job.jobCode,
          type: slim ? 'jobNodeSlim' : 'jobNodeLarge',
          data: {
            job,
            showJobStatus,
          },
          // Use this class to draw the border so that the handles lie on it
          className: jobStyles.flowJob,
          position: { x: 0, y: 0 },
        };
      }),
    [jobs, showJobStatus, slim]
  );
  const edgesMemo: Edge<JobProps>[] = useMemo(
    () =>
      jobGroupTopology.flatMap((jobGroupTopology) =>
        jobGroupTopology.successors.map((successor) => ({
          id: `e${jobGroupTopology.jobCode}-${successor}`,
          source: jobGroupTopology.jobCode,
          target: successor,
          style: { stroke: '#777' },
        }))
      ),
    [jobGroupTopology]
  );
  const nodesAndEdges: Elements<JobProps> = [...nodesMemo, ...edgesMemo];

  // --- Layout
  // Set elements in state, to make changes through layouting
  const [elements, setElements] = useState(nodesAndEdges);
  const [flowSize, setflowSize] = useState({ height: 100, width: 600 });

  // Internal node representation from react-flow needed for layouting
  const nodes = useStoreState((store: ReactFlowState) => store.nodes);
  // Get effect dependencies that 1. check that nodes have been added 2. check whether *any* height has been calculated
  const nodesLength = nodes.length;
  const nodesHeight = nodes.reduce((acc, node) => acc + node.__rf.height, 0);

  // Arrange the nodes only after 1. mount 2. elements are added (nodesLength) and
  // then 3. width/height has been calculated by react-flow (nodesHeight)
  const arrangeNodes = () => {
    if (nodesLength === 0 || nodesHeight === 0) return;

    // Layout the elements and return information about the layout like height
    const { elements: layoutedElements, layout } = getLayoutedElements(
      elements,
      nodes,
      {
        rankdir: 'LR',
        nodesep: 10,
        ranksep: 20,
        edgesep: 0, // Only sets one edge? Better to set the margin
        marginx: 5,
      }
    );
    setElements(layoutedElements);
    // Add the distance between the graph and the edges on both sides to the final width
    setflowSize({
      height: layout.height,
      width: layout.width + 2 * layout.edgesep,
    });
  };

  useEffect(() => {
    arrangeNodes();
  }, [nodesLength, nodesHeight]);

  // Do a reducer-like update of the elements data only - without changing their position (that may already be layouted)
  useEffect(() => {
    const newElements = elements.map((element) => {
      if (!isNode(element)) return element;

      const jobCode = element?.data.job.jobCode;
      const job = jobs.find((job) => job.jobCode === jobCode);

      let augurName, codeCapsuleName;
      switch (job.superType) {
        case 'augur':
          augurName = augurNames?.[job.augurCode];
          break;
        case 'code-capsule':
          codeCapsuleName = codeCapsuleNames?.[job.codeCapsuleCode];
          break;
      }

      const jobProgressSummary = progressQuery.isSuccess
        ? progressQuery.data
        : [];

      return {
        ...element,
        type: slim ? 'jobNodeSlim' : 'jobNodeLarge',
        data: {
          ...(element.data || {}),
          job,
          augurName,
          codeCapsuleName,
          showJobStatus,
          onClick,
          progressSteps: jobProgressSummary[job.jobCode],
        },
      };
    });
    setElements(newElements);
  }, [
    jobs,
    augurNames,
    codeCapsuleNames,
    showJobStatus,
    onClick,
    slim,
    // !! Do not add "progressQuery" and "elements" as dependencies, otherwise the JobGroupTopologyChart is rendering endlessly
  ]);

  return (
    <div
      style={{
        height: flowSize.height,
        width: flowSize.width,
      }}
      className={classNames('JobGroupTopologyChart', 'job_anchor')}
    >
      <ReactFlow
        elements={elements}
        nodeTypes={nodeTypes}
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
        zoomOnScroll={false}
        nodesDraggable={false}
        nodesConnectable={false}
        paneMoveable={false}
        preventScrolling={false}
        onNodeMouseEnter={(e, node) => setHoverNode(node)}
        onNodeMouseLeave={() => setHoverNode(undefined)}
      />
      <Tooltip
        anchorSelect={'.job_anchor'}
        className={'group-topology-chart_tooltip'}
        place={'right'}
        delayShow={100}
      >
        {hoverNode && (
          <div className={'tooltipContainer'}>
            <Job data={{ ...hoverNode.data, handlesVisible: false }} />
          </div>
        )}
      </Tooltip>
    </div>
  );
};

const WrappingJobGroupTopologyChart: FC<Props> = (props) => {
  return (
    <ReactFlowProvider>
      <JobGroupTopologyChart {...props} />
    </ReactFlowProvider>
  );
};
export default WrappingJobGroupTopologyChart;

import { habitatFromGroupName } from 'common/dist/constants/keycloak';
import _ from 'lodash';
import React from 'react';

import { useHabitatNames } from '../../../../core/api/habitats';
import { Group } from '../../../../store/admin/state.types';
import ThemedSwitch from '../../../atoms/themed-switch/ThemedSwitch';

export interface Props {
  groupsData: Group[];
  usersGroups: Pick<Group, 'id' | 'name' | 'path'>[];
  /** Add a User-Group mapping */
  joinGroup: (userId: string, groupId: string) => void;
  /** Remove a User-Group mapping */
  leaveGroup: (userId: string, groupId: string) => void;
  userId: string;
}

const Habitats: React.FC<Props> = ({
  groupsData,
  usersGroups,
  userId,
  joinGroup,
  leaveGroup,
}) => {
  const { data: habitatNames } = useHabitatNames();

  const habitats = Object.entries(
    _.groupBy(
      groupsData
        .filter((g) => _.isEqual(g.attributes.isHabitat, ['true']))
        .map((g) => ({ ...g, habitatCode: habitatFromGroupName(g.name) })),
      'habitatCode'
    )
  )
    .map(([_, groups]) =>
      groups.map((group) => ({
        ...group,
        speakingName: habitatNames?.[habitatFromGroupName(group.name)],
      }))
    )
    .sort((a, b) => (a[0].speakingName > b[0].speakingName ? 1 : -1));

  return (
    <div className={'groups-container ct-list'}>
      <div className={'ct-row ct-headline'}>
        <div className={'ct-col ct-col-280px'}>
          <span>Name</span>
        </div>
        <div className={'ct-col ct-col-350px'} />
        <div className={'ct-col ct-col-80px'}>
          <span>Edit</span>
        </div>
        <div className={'ct-col ct-col-80px'}>
          <span>View</span>
        </div>
      </div>
      {habitats.map((groups) => {
        const editGroup = groups.find((g) =>
          _.isEqual(g.attributes.permissions, ['edit'])
        );
        const viewGroup = groups.find((g) =>
          _.isEqual(g.attributes.permissions, ['view'])
        );
        return (
          <div className={'ct-row groups-row'}>
            <div className={'ct-col ct-col-280px'}>
              <span>{groups[0].speakingName}</span>
            </div>
            <div className={'ct-col ct-col-350px'} />
            <div className={'ct-col ct-col-80px'}>
              <ThemedSwitch
                onChange={() => {
                  if (usersGroups.map((g) => g.id).includes(editGroup.id)) {
                    leaveGroup(userId, editGroup.id);
                  } else {
                    joinGroup(userId, editGroup.id);
                  }
                }}
                checked={usersGroups.map((g) => g.id).includes(editGroup.id)}
                height={20}
                width={40}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                themeColor={'primary'}
              />
            </div>
            <div className={'ct-col ct-col-80px'}>
              <ThemedSwitch
                onChange={() => {
                  if (usersGroups.map((g) => g.id).includes(viewGroup.id)) {
                    leaveGroup(userId, viewGroup.id);
                  } else {
                    joinGroup(userId, viewGroup.id);
                  }
                }}
                checked={usersGroups.map((g) => g.id).includes(viewGroup.id)}
                height={20}
                width={40}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                themeColor={'primary'}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Habitats;

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import HttpStatusCodes from 'common/dist/constants/httpStatusCodes';
import { useSelector } from 'react-redux';

import {
  apiRequest,
  CompletedWorkbenchRequest,
  patchApiRequest,
  putApiRequest,
} from './_apiRequests';
import * as DirectoryApi from './directory';
import { API_PORT } from './git.notebook';
import * as NotebookApi from './notebook';
import {
  AugurSettingsWithAugurProperties,
  ModuleConfiguration,
} from '../../../components/pages/augur/type';
import { notebookUser } from '../../../redux/workbench/selectors/notebookUser.selector';
import { JupyterContentElement } from '../../../store/workbench/state.types';
import { fetchQueryFn } from '../_tools';

export const contentKeys = {
  all: () => ['content'] as const,
  some: (jupyterUser: string) => [...contentKeys.all(), jupyterUser] as const,
  content: (jupyterUser: string, path?: string) =>
    [...contentKeys.some(jupyterUser), path] as const,
};

export function fetchContent(
  jupyterUser: string,
  path?: string
): CompletedWorkbenchRequest<JupyterContentElement> {
  const url = `/jupyter/user/${jupyterUser}/api/contents/${encodeURIComponent(
    path
  )}`;
  return apiRequest(url);
}

export function useContent(
  path: string,
  enabled = true
): UseQueryResult<JupyterContentElement> {
  // get current jupyter user from Redux state
  const jupyterUser = useSelector((state) => notebookUser(state));
  const key = contentKeys.content(jupyterUser, path);
  return useQuery(
    key,
    //@ts-ignore
    () => fetchQueryFn(key, () => fetchContent(jupyterUser, path)),
    { enabled }
  );
}

export function renameContent(oldPath, newPath, jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/api/contents/${encodeURIComponent(
    oldPath
  )}`;
  const body = {
    path: newPath,
  };
  return patchApiRequest(url, body);
}

export function restoreContent(name, targetPath, jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/proxy/${API_PORT}/restore`;
  const body = {
    name,
    targetPath,
  };
  return putApiRequest(url, body);
}

export function putFile(path, content, jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/api/contents/${path}`;
  const body = {
    type: 'file',
    format: 'text',
    path,
    content,
  };
  return putApiRequest(url, body);
}

export async function saveConfig(
  notebookUser: string,
  config: ModuleConfiguration,
  activeProjectPath: string
) {
  const configPath = [activeProjectPath];
  const configFile = '.asconfig.json';
  configPath.push(configFile);
  await NotebookApi.saveFile(
    configPath.join('/'),
    JSON.stringify(config, null, 2),
    notebookUser
  );
}

export async function saveSettings(
  notebookUser: string,
  settings: AugurSettingsWithAugurProperties['settingsData'],
  activeProjectPath: string,
) {
  //Get the current path in the workbench
  const settingsPath = [activeProjectPath];
  settingsPath.push('asruns');
  //Check if directory exists
  const { status: dirExists } = await DirectoryApi.checkDirectoryExists(
    notebookUser,
    settingsPath.join('/')
  );
  if (dirExists === HttpStatusCodes.NOT_FOUND) {
    //2. Create the asruns directory. It is just possible to add an empty directory and rename it afterwards
    const { response, error } = await DirectoryApi.createDirectory(
      notebookUser
    );
    if (response && typeof response !== 'string') {
      const name = response.name;
      const newPathRaw = settingsPath.join('/');
      const newPath = newPathRaw.startsWith('/')
        ? newPathRaw.slice(1)
        : newPathRaw;
      await DirectoryApi.renameDirectory(name, newPath, notebookUser);
    } else {
      return { response: null, error };
    }
  }
  settingsPath.push('augur_settings.json');
  await NotebookApi.saveFile(
    settingsPath.join('/'),
    JSON.stringify(settings, null, 2),
    notebookUser
  );
}

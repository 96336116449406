import { UseMutationResult } from '@tanstack/react-query';
import msgsForm from 'common/dist/messages/form';
import moduleMsgs from 'common/dist/messages/modules';
import notificationMsgs from 'common/dist/messages/notifications';
import { ModuleWithAllRelations } from 'common/dist/types/module';
import { User } from 'common/dist/types/users';
import React, { FC, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ModuleIcon } from './ModuleIcon';
import styles from './styles.module.scss';
import {
  useAddModule,
  useEditModule,
  useModuleByCode,
  useModuleNames,
  useModules,
} from '../../../core/api/modules';
import { useRepositorySlugs } from '../../../core/api/repositories';
import * as NOTIFICATION_TYPES from '../../../core/notifications';
import { sendNotification } from '../../../redux/modules/notifications.module';
import { RootState, useAppDispatch } from '../../../store/store';
import { DropdownSelectInput } from '../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { IntlTextInputLine } from '../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import {
  moduleDetailsLink,
  overviewModulesLink,
} from '../../collaborationSpace/routes';
import {
  validateRepositoryName,
  validateRepositorySlug,
} from '../../collaborationSpace/wizards/repository-add/validation';
import BubbleStep from '../../molecules/bubble-step/BubbleStep';
import Wizard from '../wizard/Wizard';

export type ModuleFormData = ModuleWithAllRelations & {
  templateId?: OptionType;
};

type OptionType = {
  label: string;
  value: number;
};

const NewModuleWizard: FC<Partial<ModuleFormData>> = (props) => {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const { code, templateId } = props;
  const isEdit = Boolean(code);
  const module: ModuleWithAllRelations = useModuleByCode(code, isEdit).data;
  const templates = useModules(
    {
      has_repository: 'true',
    },
    !isEdit
  );

  const { data: moduleNames, isLoading: isModuleNamesLoading } =
    useModuleNames();
  const { data: slugNames, isLoading: isSlugNamesLoading } =
    useRepositorySlugs();

  const options: OptionType[] = (templates.data || []).map((template) => ({
    label: `${template.name} (${template.code})`,
    value: template.repository.id,
  }));
  //First we need to get the unique identifier for the base template
  const templateRepoId = (templates?.data || []).find(
    (x) => x.name === 'Base Template'
  )?.repository?.id;
  //Find the correct option
  const defaultTemplateId =
    templateId ?? options.find((x) => x.value === templateRepoId);

  const methods = useForm<ModuleFormData>({
    mode: 'all',
    defaultValues: { templateId: defaultTemplateId, ...module },
    values: { templateId: defaultTemplateId, ...module },
  });

  const {
    formState: { isDirty, isValid, isSubmitSuccessful, isSubmitting },
    control,
    getFieldState,
  } = methods;

  if (isSubmitSuccessful && !code) {
    history.push(overviewModulesLink());
  } else if (isSubmitSuccessful && code) {
    history.push(moduleDetailsLink(module.code));
  }
  const displayToSlug = (display: string) =>
    display
      .trim()
      .toLowerCase()
      .replaceAll(/[^a-z0-9-]/g, '-')
      .replace(/-+/g, '-')
      .replace(/(^-)|(-$)/, '');
  const [isSlugUnmodified, setIsSlugUnmodified] = useState(true);

  const user = useSelector<RootState, User>((state) => state.currentUser);

  const editHook = useEditModule(code);
  const addHook = useAddModule();
  const moduleMutation: UseMutationResult<
    unknown,
    unknown,
    ModuleFormData | ModuleWithAllRelations
  > = isEdit ? editHook : addHook;

  const onSubmit = async (data: ModuleFormData) => {
    if (!data?.moduleAvatar?.imageData) data.moduleAvatar = undefined;
    await moduleMutation
      .mutateAsync(data)
      .then(() => {
        if (isEdit) {
          dispatch(
            sendNotification(
              notificationMsgs.msgTitleModuleUpdateSuccess.defaultMessage,
              // @ts-ignore
              notificationMsgs.msgDescriptionModuleUpdateSuccess.defaultMessage,
              NOTIFICATION_TYPES.event
            )
          );
        } else {
          dispatch(
            sendNotification(
              notificationMsgs.msgTitleModuleAddSuccess.defaultMessage,
              // @ts-ignore
              notificationMsgs.msgDescriptionModuleAddSuccess.defaultMessage,
              NOTIFICATION_TYPES.event
            )
          );
        }
      })
      .catch((e) => {
        if (isEdit) {
          dispatch(
            sendNotification(
              notificationMsgs.msgTitleModuleUpdateFailure.defaultMessage,
              // @ts-ignore
              notificationMsgs.msgDescriptionModuleUpdateFailure.defaultMessage,
              NOTIFICATION_TYPES.error
            )
          );
        } else {
          dispatch(
            sendNotification(
              notificationMsgs.msgTitleModuleAddFailure.defaultMessage,
              // @ts-ignore
              notificationMsgs.msgDescriptionModuleAddFailure.defaultMessage,
              NOTIFICATION_TYPES.error
            )
          );
        }
      });
  };

  const renderNameStep = () => {
    const nameFieldState = getFieldState('name', methods.formState);
    const repoNameFieldState = !code
      ? getFieldState('repository.repoName', methods.formState)
      : undefined;

    const invalid = nameFieldState.invalid || repoNameFieldState?.invalid;
    const isDirty = nameFieldState.isDirty || repoNameFieldState?.isDirty;
    const isError = !!nameFieldState.error || !!repoNameFieldState?.error;

    return (
      <BubbleStep
        title={moduleMsgs.moduleWizardModuleNameStepTitle}
        description={moduleMsgs.moduleWizardModuleNameStepDescription}
        stepNumber={1}
        isValid={isDirty && !invalid}
        isErroneous={isDirty && isError}
      >
        <Controller
          name={'name'}
          control={control}
          rules={{
            validate: (name: string) => {
              // name unchanged in edit mode
              if (isEdit && name === module.name) return true;

              return validateRepositoryName(
                name,
                moduleNames,
                isModuleNamesLoading
              );
            },
          }}
          render={({ field, fieldState }) => {
            const { ref, onChange, ...rest } = field; // extract ref to pass as inputRef
            return (
              <IntlTextInputLine
                label={'Enter Module Name'}
                placeholder={'Enter Module Name'}
                {...rest}
                {...fieldState}
                // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                isTouched={true}
                inputRef={ref}
                error={fieldState.error?.message}
                // custom onChange to update slug
                onChange={(e) => {
                  onChange(e);
                  methods.setValue(
                    'repository.repoName',
                    isSlugUnmodified
                      ? displayToSlug(e.target.value)
                      : methods.getValues('repository.repoName'),
                    { shouldDirty: true, shouldValidate: true }
                  );
                }}
              />
            );
          }}
        />
        {!code && (
          <div className={styles.slugContainer}>
            <div className={styles.slugInfo}>
              <span>
                {user?.firstName}&nbsp;{user?.lastName}
              </span>
              <span>/</span>
            </div>

            <Controller
              name={'repository.repoName'}
              rules={{
                validate: (slug: string) => {
                  return validateRepositorySlug(
                    slug,
                    slugNames,
                    isSlugNamesLoading
                  );
                },
              }}
              control={control}
              render={({ field, fieldState }) => {
                const { ref, onChange, ...rest } = field; // extract ref to pass as inputRef
                return (
                  <IntlTextInputLine
                    placeholder={'Enter Repository Slug'}
                    {...rest}
                    {...fieldState}
                    // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                    isTouched={true}
                    inputRef={ref}
                    error={fieldState.error?.message}
                    // custom onChange to update slug
                    onChange={(e) => {
                      setIsSlugUnmodified((e.target.value ?? '').length === 0);
                      onChange(e);
                    }}
                  />
                );
              }}
            />
          </div>
        )}
      </BubbleStep>
    );
  };

  const renderModuleIconStep = () => {
    const { invalid, isDirty, error } = getFieldState(
      'moduleAvatar',
      methods.formState
    );

    return (
      <BubbleStep
        title={moduleMsgs.moduleWizardModuleUploadIconStepTitle}
        description={moduleMsgs.moduleWizardModuleUploadIconStepDescription}
        stepNumber={3}
        isValid={isDirty && !invalid}
        isErroneous={isDirty && !!error}
      >
        {/*Show Icon Upload*/}
        {module ? <ModuleIcon {...module.moduleAvatar} /> : <ModuleIcon />}
      </BubbleStep>
    );
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        style={{ height: '100%' }}
      >
        <Wizard
          headline={
            isEdit
              ? moduleMsgs.moduleWizardHeadlineEdit
              : moduleMsgs.moduleWizardHeadline
          }
          buttons={[
            {
              color: 'white',
              label: msgsForm.cancel,
              onClick: () => {
                history.goBack();
              },
            },
            {
              color: 'secondary',
              type: 'submit',
              label: msgsForm.submit,
              disabled: !isDirty || !isValid,
              isBusy: isSubmitting,
            },
          ]}
        >
          {renderNameStep()}

          <Controller
            name={'description'}
            control={control}
            rules={{
              maxLength: {
                value: 128,
                message: '128 characters are the maximum length.',
              },
            }}
            render={({ field, fieldState }) => {
              const { ref, ...rest } = field; // extract ref to pass as inputRef
              return (
                <BubbleStep
                  title={moduleMsgs.moduleWizardModuleDescriptionStepTitle}
                  description={
                    moduleMsgs.moduleWizardModuleDescriptionStepDescription
                  }
                  stepNumber={2}
                  isErroneous={fieldState.isDirty && !!fieldState.error}
                  isValid={fieldState.isDirty && !fieldState.invalid}
                >
                  <IntlTextInputLine
                    label={'Enter a short Description'}
                    placeholder={'Enter a short description for the module'}
                    {...rest}
                    {...fieldState}
                    // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                    isTouched={true}
                    inputRef={ref}
                    error={fieldState.error?.message}
                  />
                </BubbleStep>
              );
            }}
          />

          {renderModuleIconStep()}
          {!code && (
            <Controller
              name={'templateId'}
              control={control}
              render={({ field, fieldState, formState }) => {
                const { ref, ...rest } = field; // extract ref to pass as inputRef
                return (
                  <BubbleStep
                    title={moduleMsgs.moduleWizardModuleTemplateStepTitle}
                    description={
                      moduleMsgs.moduleWizardModuleTemplateStepDescription
                    }
                    stepNumber={4}
                    isErroneous={fieldState.isDirty && !!fieldState.error}
                    isValid={fieldState.isDirty && !fieldState.invalid}
                  >
                    <DropdownSelectInput<OptionType>
                      label={'Select a template'}
                      placeholder={'Template'}
                      isClearable={true}
                      options={options}
                      {...rest}
                      {...fieldState}
                      {...formState}
                    />
                  </BubbleStep>
                );
              }}
            />
          )}
        </Wizard>
      </form>
    </FormProvider>
  );
};

export default NewModuleWizard;

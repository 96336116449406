import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import HabitatDetails from './HabitatDetails';
import {
  loadGroups,
  loadUsersList,
} from '../../../../redux/modules/admin.users.module';
import { DeprecatedRootState } from '../../../../store/state.type';

export function mapStateToProps(
  state: DeprecatedRootState,
  { match }: RouteComponentProps<{ groupIds: string }>
) {
  const { groupIds } = match.params;
  const editId = groupIds.split('&')[0];
  const viewId = groupIds.split('&')[1];
  const editGroup = state.admin.groups.data.find((g) => g.id === editId);
  const viewGroup = state.admin.groups.data.find((g) => g.id === viewId);

  return {
    editGroup,
    viewGroup,
    users: state.admin.users.data,
  };
}

export const mapDispatchToProps = {
  loadUsersList,
  loadGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(HabitatDetails);

// Contains selector functions that aren't offered by redux-form natively

/**
 * Selects the value of a field. Is provided by redux-form, but doesn't work for some reason
 * @param state
 * @param formName
 * @param fieldName
 * @returns {string|*}
 */
export function selectValue(state, formName, fieldName) {
  if (!state.form[formName]) return '';
  if (!state.form[formName].values) return '';
  return state.form[formName].values[fieldName];
}

import classNames from 'classnames';
import orchestrationMessages from 'common/dist/messages/orchestration';
import { JobGroupType } from 'common/dist/types/job';
import React, { FC } from 'react';
import { FiClock, FiEdit, FiPlay, FiTrash2 } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';
import { useAugurNames } from '../../../../core/api/augurs';
import { useCodeCapsuleNames } from '../../../../core/api/codeCapsules';
import { setDetailsOrigin } from '../../../../redux/modules/orchestration.jobdetails.module';
import AvatarIconContainer from '../../../atoms/avatar-icon/AvatarIcon';
import Button from '../../../atoms/button/Button';
import { JOB_GROUP_VARIANTS } from '../../../orchestration/details/job-group-details/JobGroupDetails';
import { orchestrationRoutes } from '../../../orchestration/routes';
import JobGroupTopologyChart from '../job-group-topology-chart/JobGroupTopologyChart';

export interface Props {
  jobGroup: JobGroupType;
  jobGroupCode: string;
  runAction: () => void;
  editLink: string;
  createScheduleFromJobGroupLink: string;
  deleteAction: () => void;
}

const JobGroup: FC<Props> = (props) => {
  const {
    jobGroup,
    editLink,
    createScheduleFromJobGroupLink,
    deleteAction,
    runAction,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const { data: augurNames } = useAugurNames();
  const { data: codeCapsuleNames } = useCodeCapsuleNames();

  return (
    <div
      className={classNames(styles.item, styles.jobGroup)}
      data-testid={`JobGroupRow-${jobGroup.name}`}
      onClick={() => {
        dispatch(setDetailsOrigin(history.location.pathname));
        history.push(
          `${orchestrationRoutes.jobGroupDetails.path}/${JOB_GROUP_VARIANTS.jobTemplate}/${jobGroup.code}`
        );
      }}
    >
      <div
        className={styles.topology}
        style={{
          gridRow: '1',
          gridColumn: '1',
        }}
      >
        <JobGroupTopologyChart
          jobs={jobGroup.jobs}
          jobGroupTopology={jobGroup.jobGroupTopology}
          showJobStatus={false}
          augurNames={augurNames || {}}
          codeCapsuleNames={codeCapsuleNames || {}}
          slim
        />
      </div>
      <div
        className={styles.info}
        style={{
          gridRow: '1',
          gridColumn: '2',
        }}
      >
        <strong className={styles.field}>{jobGroup.name}</strong>
        <div className={styles.description}>
          <div className={styles.box} title={jobGroup.description || ''}>
            {jobGroup.description || ''}
          </div>
        </div>
      </div>
      <div
        className={styles.userIcon}
        style={{
          gridRow: '1',
          gridColumn: '3',
        }}
      >
        {jobGroup.createdBy && (
          <AvatarIconContainer userId={jobGroup.createdBy} />
        )}
      </div>
      <div
        className={styles.buttons}
        style={{
          gridRow: '1',
          gridColumn: '4',
        }}
      >
        <div
          className={styles.buttonsWrapper}
          onClick={(e) => e.stopPropagation()}
        >
          <Button
            data-testid={'Run now'}
            Icon={() => <FiPlay size={18} />}
            form={'squared'}
            onClick={runAction}
            title={orchestrationMessages.jobGroupRun}
          />
          <Button
            data-testid={'Create Job Schedule'}
            Icon={() => <FiClock size={18} />}
            form={'squared'}
            linkTo={createScheduleFromJobGroupLink}
            title={orchestrationMessages.jobGroupCreateSchedule}
          />
          <Button
            data-testid={'Edit Job Group'}
            Icon={() => <FiEdit size={18} />}
            form={'squared'}
            linkTo={editLink}
            title={orchestrationMessages.jobGroupEdit}
          />
          <Button
            data-testid={'Delete Job Group'}
            Icon={() => <FiTrash2 size={18} />}
            form={'squared'}
            onClick={deleteAction}
            title={orchestrationMessages.jobGroupDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default JobGroup;

import formMessages from 'common/dist/messages/form';
import habitatMessages from 'common/dist/messages/habitats';
import msgsNotifications from 'common/dist/messages/notifications';
import { Habitat } from 'common/dist/types/habitat';
import { validateHabitatName } from 'common/dist/validation/habitat';
import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { HabitatForm } from './habitat.form';
import styles from './styles.module.scss';
import { useAugurs } from '../../../core/api/augurs';
import {
  useAddHabitat,
  useDeleteHabitat,
  useHabitat,
  useHabitatNames,
  useUpdateHabitat,
} from '../../../core/api/habitats';
import {
  error as ERROR_NOTIFICATION,
  event as EVENT_NOTIFICATION,
} from '../../../core/notifications';
import { sendNotification } from '../../../redux/modules/notifications.module';
import { isAdmin } from '../../../redux/selectors/user.selector';
import { DeprecatedRootState } from '../../../store/state.type';
import { useAppDispatch } from '../../../store/store';
import Busy from '../../atoms/busy/Busy';
import { ButtonProps } from '../../atoms/button/Button';
import { IntlTextInputLine } from '../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import * as routes from '../../index/routes';
import { HabitatRouteParams } from '../../index/routes';
import BubbleStep from '../../molecules/bubble-step/BubbleStep';
import ConfirmationModal from '../../organisms/confirmation-modal/ConfirmationModal';
import MainContainer from '../main-container/MainContainer';
import Wizard from '../wizard/Wizard';

type InnerProps = {
  headline: string;
  onSubmit: (formValues: HabitatForm) => void | Promise<void>;
  onDelete?: () => void | Promise<void>;
  isDeleting?: boolean;
  habitat?: Habitat;
};

const HabitatWizard: FC<InnerProps> = ({
  headline,
  onSubmit,
  onDelete,
  isDeleting,
  habitat,
}) => {
  const history = useHistory();
  const intl = useIntl();

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm<HabitatForm>({
    mode: 'all',
    defaultValues: {
      name: '',
    },
    values: {
      name: habitat?.name,
    },
  });
  const [showDeleteHabitatConfirm, setShowDeleteHabitatConfirm] =
    useState(false);
  const dispatch = useAppDispatch();

  const buttons: ButtonProps[] = [
    ...(onDelete
      ? [
          {
            color: 'red',
            label: formMessages.delete,
            onClick: () => setShowDeleteHabitatConfirm(true),
            isBusy: isDeleting,
            disabled: isSubmitting,
          } satisfies ButtonProps,
        ]
      : []),
    {
      color: 'white',
      label: formMessages.cancel,
      onClick: () => history.push(routes.app.prefix + routes.app.models),
    },
    {
      color: 'secondary',
      type: 'submit',
      label: formMessages.submit,
      // There is currently a bug in Dev Mode for RHF where isDirty is not updated under certain conditions
      // In this case this is the case if you use async validation that is dependent on component props (here: habitat)
      // This might be connected to this bug report: https://github.com/react-hook-form/react-hook-form/pull/12039
      disabled: !isDirty || !isValid || isDeleting,
      isBusy: isSubmitting,
    },
  ];

  const {
    data: habitatNames,
    isLoading: isHabitatNamesLoading,
    isError: isHabitatNamesError,
    error: habitatNamesError,
  } = useHabitatNames();

  return (
    <MainContainer fullWidth scrollableY={false}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
        <Wizard headline={headline} buttons={buttons}>
          <Controller
            name={'name'}
            control={control}
            rules={{
              validate: (name: string) => {
                const syncError = validateHabitatName(name);
                if (syncError) return intl.formatMessage(syncError);

                // check if name already exists except if it is the current name of the habitat
                if (habitat?.name !== name) {
                  if (isHabitatNamesLoading) {
                    return false;
                  } else if (isHabitatNamesError) {
                    return JSON.stringify(habitatNamesError);
                  } else if (Object.values(habitatNames).includes(name)) {
                    return intl.formatMessage(
                      habitatMessages.habitatWizardNameErrorAlreadyExists
                    );
                  }
                }

                return true;
              },
            }}
            render={({ field, fieldState }) => {
              const { ref, ...rest } = field;
              return (
                <BubbleStep
                  description={habitatMessages.habitatWizardNameDescription}
                  stepNumber={1}
                  title={habitatMessages.habitatWizardNameTitle}
                  isErroneous={fieldState.isDirty && !!fieldState.error}
                  isValid={fieldState.isDirty && !fieldState.invalid}
                >
                  <IntlTextInputLine
                    label={'Enter Habitat Name'}
                    placeholder={'Enter Habitat Name'}
                    {...rest}
                    {...fieldState}
                    // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                    isTouched={true}
                    inputRef={ref}
                    error={fieldState.error?.message}
                  />
                </BubbleStep>
              );
            }}
          />
          {habitat && (
            <ConfirmationModal
              secure={true}
              show={showDeleteHabitatConfirm}
              secureInput={habitat.name}
              payload={{
                habitatCode: habitat.code,
                habitatName: habitat.name,
              }}
              buttonConfirmAction={onDelete}
              hideModal={() => setShowDeleteHabitatConfirm(false)}
              buttonConfirmText={formMessages.delete}
              buttonConfirmColor={'red'}
              description={{
                ...habitatMessages.habitatDeleteDescription,
                values: {
                  nameBold: <b>{habitat.name}</b>,
                  nameCode: <code>{habitat.name}</code>,
                },
              }}
              headline={habitatMessages.habitatDeleteTitle}
              headlineColor={'red'}
            />
          )}
        </Wizard>
      </form>
    </MainContainer>
  );
};

export const AddHabitatWizard: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { mutateAsync } = useAddHabitat();

  const onSubmit = async (form: HabitatForm) => {
    try {
      await mutateAsync(form);
      history.push(routes.app.prefix + routes.app.models);
      dispatch(
        sendNotification(
          msgsNotifications.msgTitleAddedHabitat.id,
          // @ts-ignore
          msgsNotifications.msgDescriptionAddedHabitat.id,
          EVENT_NOTIFICATION,
          { habitatName: form.name }
        )
      );
    } catch (e) {
      console.error('There was an error while trying to add a habitat:', e);
      dispatch(
        sendNotification(
          msgsNotifications.msgTitleAddHabitatFailed.id,
          // @ts-ignore
          msgsNotifications.msgDescriptionAddHabitatFailed.id,
          ERROR_NOTIFICATION,
          { habitatName: form.name }
        )
      );
    }
  };

  return <HabitatWizard headline={'Add Habitat'} onSubmit={onSubmit} />;
};

export const UpdateHabitatWizard: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const hasPermission = useSelector<DeprecatedRootState, boolean>(isAdmin);
  const { habitatCode } = useParams<HabitatRouteParams>();
  const { data: habitat, isLoading: isLoadingHabitat } =
    useHabitat(habitatCode);
  const { data: augurs, isLoading: isLoadingAugurs } = useAugurs(habitatCode);

  const { mutateAsync: updateMutateAsync } = useUpdateHabitat(habitatCode);
  const { mutateAsync: deleteMutateAsync, isLoading: deleteIsLoading } =
    useDeleteHabitat(habitatCode);

  if (isLoadingHabitat || isLoadingAugurs) {
    return <Busy isBusy={true} />;
  }

  const onSubmit = async (form: HabitatForm) => {
    try {
      await updateMutateAsync(form);
      history.push(routes.app.prefix + routes.app.models);
      dispatch(
        sendNotification(
          msgsNotifications.msgTitleEditedHabitat.id,
          // @ts-ignore
          msgsNotifications.msgDescriptionEditedHabitat.id,
          EVENT_NOTIFICATION,
          { habitatName: form.name }
        )
      );
    } catch (e) {
      console.error('There was an error while trying to update a habitat:', e);
      dispatch(
        sendNotification(
          msgsNotifications.msgTitleDeletedHabitatFailed.id,
          // @ts-ignore
          msgsNotifications.msgDescriptionDeletedHabitatFailed.id,
          ERROR_NOTIFICATION,
          { habitatName: habitat.name } // old name because it wasn't changed
        )
      );
    }
  };

  const allowDelete = hasPermission && augurs?.length <= 0;
  const onDelete = async () => {
    try {
      await deleteMutateAsync(undefined);
      history.push(routes.app.prefix + routes.app.models);
      dispatch(
        sendNotification(
          msgsNotifications.msgTitleDeletedHabitat.id,
          // @ts-ignore
          msgsNotifications.msgDescriptionDeletedHabitat.id,
          EVENT_NOTIFICATION,
          { habitatName: habitat.name }
        )
      );
    } catch (e) {
      console.error('There was an error when trying to delete a habitat:', e);
      dispatch(
        sendNotification(
          msgsNotifications.msgTitleDeletedHabitatFailed.id,
          // @ts-ignore
          msgsNotifications.msgDescriptionDeletedHabitatFailed.id,
          ERROR_NOTIFICATION,
          { habitatName: habitat.name }
        )
      );
    }
  };

  return (
    <HabitatWizard
      headline={'Update Habitat'}
      onSubmit={onSubmit}
      onDelete={allowDelete && onDelete}
      isDeleting={deleteIsLoading}
      habitat={habitat}
    />
  );
};

import { connect, ConnectedProps } from 'react-redux';

import JobGroupExecution from './JobGroupExecution';
import { setDetailsOrigin } from '../../../../redux/modules/orchestration.jobdetails.module';
import {
  cancelJobGroup,
  hideJobGroupConfirm,
  showJobGroupConfirm,
} from '../../../../redux/modules/orchestration.queue.module';
import { runJobGroup } from '../../../../store/jobGroups/slice';
import { DeprecatedRootState } from '../../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState) {
  return {
    cancelJobGroupConfirm: state.queue.pre.cancelJobGroupConfirm,
  };
}

export const mapDispatchToProps = {
  setDetailsOrigin,
  cancelJobGroup,
  showJobGroupConfirm,
  hideJobGroupConfirm,
  runJobGroup,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type connectedProps = ConnectedProps<typeof connector>;
export default connector(JobGroupExecution);

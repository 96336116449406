import { createAction } from 'redux-act';

export const setActiveProjectPath = createAction(
  'Set active project path',
  (path) => path
);

export const reducer = {
  [setActiveProjectPath]: (state, path) => ({
    ...state,
    activeProject: {
      ...state.activeProject,
      path: path,
    },
  }),
};

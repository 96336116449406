import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import msgsNotifications from 'common/dist/messages/notifications';

import { queryClient } from '../../components/index/react_query';
import { modulesKeys } from '../../core/api/modules';
import {
  deleteApiRequest,
  getApiRequest,
  postApiRequest,
} from '../../core/api/workbench/_apiRequests';
import { event as EVENT_NOTIFICATION } from '../../core/notifications';
import { sendNotification } from '../../redux/modules/notifications.module';

export type WorkbenchTerminalsState = {
  [relativeModulePath: string]: {
    stdout: string;
    // We want to keep the stdout, even if the jobs has finished running
    running: boolean;
    /**
     * We just want to display the prompt and our command and then the output
     * So there is a preamble we want to ignore, before we start adding the output to the state
     * We say the preamble is finished once it wants to set the title via osc ansi sequences for the first time
     * 1. empty prompt/ps1 and setting the title for the first time <- preamble, ignored
     * 2. prompt and our command being printed. (this may also include setting a title, since the command may change the dir for example) <- not ignored and nothing else ignored from then on
     * 3.- Rest of the output
     */
    preambleFinished: boolean;
  };
};

// FIXME:CM since the terminals can't be passed an identifier like the sessions, on reload we forget which terminal was for a module
export const initial: WorkbenchTerminalsState = {};

// This object holds the open socket objects. This does not seem very nice, but we also do that with other sockets
const sockets: {
  [relativeModulePath: string]: { socket: WebSocket; id: string };
} = {};

// Type that's also used by the JupyterHub responses. See https://jupyter-server.readthedocs.io/en/latest/developers/rest-api.html#get--api-terminals-terminal_id
type JupyterHubTerminal = { name: string };

const slice = createSlice({
  name: 'workbenchTerminals',
  initialState: initial,
  reducers: {
    updateStdout: (
      state,
      action: PayloadAction<{
        relativeModulePath: string;
        stdout: string;
        includesOsc: boolean;
      }>
    ) => {
      if (!state[action.payload.relativeModulePath]) {
        state[action.payload.relativeModulePath] = {
          stdout: '',
          running: true,
          preambleFinished: false,
        };
      }
      // There may be multiple messages with osc (title), but we only consider the first one to be the preamble and want to ignore just that one
      if (
        action.payload.includesOsc &&
        !state[action.payload.relativeModulePath].preambleFinished
      ) {
        state[action.payload.relativeModulePath].preambleFinished = true;
        return;
      }
      if (state[action.payload.relativeModulePath].preambleFinished) {
        state[action.payload.relativeModulePath].stdout +=
          action.payload.stdout;
      }
    },
    clearStdout: (
      state,
      action: PayloadAction<{ relativeModulePath: string }>
    ) => {
      if (!state[action.payload.relativeModulePath]?.running) {
        delete state[action.payload.relativeModulePath];
      } else if (state[action.payload.relativeModulePath]) {
        state[action.payload.relativeModulePath].stdout = '';
      }
    },
    setStopRunning: (
      state,
      action: PayloadAction<{ relativeModulePath: string }>
    ) => {
      if (state[action.payload.relativeModulePath]) {
        state[action.payload.relativeModulePath].running = false;
      }
    },
  },
});

// https://en.wikipedia.org/wiki/ANSI_escape_code#OSC_(Operating_System_Command)_sequences like setting the window title
// eslint-disable-next-line no-control-regex
const oscRegex = /\u001b]0;[^\u0007]*?\u0007/g;
// Strip enable bracketed paste "\e[?2004h" and disable "\e[?2004l"
// eslint-disable-next-line no-control-regex
const bracketedPasteRegex = /\u001b\[\?2004[hl]/g;

export const runCmd = createAsyncThunk<
  void,
  { notebookUser: string; relativeModulePath: string; cmd: string },
  { rejectValue: string }
>(
  'workbenchTerminals/runCmd',
  async ({ notebookUser, relativeModulePath, cmd }, thunkAPI) => {
    const { response: response1, error: error1 } = await getApiRequest<
      JupyterHubTerminal[]
    >(`/jupyter/user/${notebookUser}/api/terminals`);
    if (typeof response1 === 'string') {
      console.error('Unexpected response', response1);
      return;
    }
    if (response1) {
      await Promise.all(
        response1.map((terminal) =>
          deleteApiRequest(
            `/jupyter/user/${notebookUser}/api/terminals/${terminal.name}`
          )
        )
      );
    }

    const { response, error } = await postApiRequest<JupyterHubTerminal>(
      `/jupyter/user/${notebookUser}/api/terminals`
    );
    if (error) {
      console.error(error);
      return;
    }
    if (typeof response === 'string') {
      console.error('Unexpected response', response);
      return;
    }
    const id = response.name;

    if (sockets[relativeModulePath]) {
      console.log('connection already exists, exiting');
      return;
    }
    const wsUrl = `${location.protocol.includes('https') ? 'wss://' : 'ws://'}${
      location.hostname + (location.port ? ':' + location.port : '')
    }/jupyter/user/${notebookUser}/terminals/websocket/${id}`;
    const socket: WebSocket = new WebSocket(wsUrl);
    socket.onclose = (event) => {
      // TODO not very nice but we rely on having the latest job for running other cmds and if this terminated it probably finished a job
      queryClient.invalidateQueries(modulesKeys.files(relativeModulePath));
      delete sockets[relativeModulePath];
      thunkAPI.dispatch(slice.actions.setStopRunning({ relativeModulePath }));
      thunkAPI.dispatch(
        sendNotification(
          msgsNotifications.titleWorkbenchTerminalSessionClosed.id,
          // @ts-ignore
          msgsNotifications.titleWorkbenchTerminalSessionClosed.id,
          EVENT_NOTIFICATION
        )
      );
    };
    socket.onerror = (event) => {
      console.error('WebSocket error: ', event);
    };
    socket.onmessage = (event) => {
      // Documentation for terminado https://github.com/jupyter/terminado/blob/v0.15.0/terminado/websocket.py#L84
      const [type, stdout] = JSON.parse(event.data);
      if (type === 'stdout') {
        if (typeof stdout !== 'string') {
          console.warn('Unexpected message', stdout);
          return;
        }
        // The osc check is also important for the preamble handling (see other comments). So we can't just combine it with the other regex to strip all at once
        const includesOsc = stdout.search(oscRegex) !== -1;
        let stdoutWithoutOsc = stdout;
        if (includesOsc) {
          stdoutWithoutOsc = stdout.replace(oscRegex, '');
        }
        thunkAPI.dispatch(
          slice.actions.updateStdout({
            relativeModulePath,
            stdout: stdoutWithoutOsc.replace(bracketedPasteRegex, ''),
            includesOsc,
          })
        );
      }
    };
    socket.onopen = () => {
      thunkAPI.dispatch(slice.actions.clearStdout({ relativeModulePath }));
      // width, height. Could theoretically be infinite since we never want any linebreaks added. Let's go with full hd
      socket.send(JSON.stringify(['set_size', 1920, 1080]));
      socket.send(JSON.stringify(['stdin', cmd]));
      thunkAPI.dispatch(
        sendNotification(
          msgsNotifications.titleWorkbenchTerminalSessionStarted.id,
          // @ts-ignore
          msgsNotifications.titleWorkbenchTerminalSessionStarted.id,
          EVENT_NOTIFICATION
        )
      );
    };
    sockets[relativeModulePath] = { socket, id };
  }
);

export const sendInterrupt = createAsyncThunk<
  void,
  { relativeModulePath: string },
  { rejectValue: string }
>('workbenchTerminals/sendInterrupt', ({ relativeModulePath }, thunkAPI) => {
  const socketMeta = sockets[relativeModulePath];
  if (!socketMeta || socketMeta.socket.readyState !== WebSocket.OPEN) {
    // If there is nothing to interrupt, quitting is fine
    return thunkAPI.rejectWithValue('websocket does not exist or is not open');
  }
  socketMeta.socket.send(JSON.stringify(['stdin', '\u0003']));
  thunkAPI.dispatch(
    sendNotification(
      msgsNotifications.titleWorkbenchTerminalInterrupt.id,
      // @ts-ignore
      msgsNotifications.titleWorkbenchTerminalInterrupt.id,
      EVENT_NOTIFICATION
    )
  );
});

export const stopAllTerminals = createAsyncThunk<
  void,
  { notebookUser: string },
  { rejectValue: string }
>('workbenchTerminals/sendInterrupt', async ({ notebookUser }, thunkAPI) => {
  const url = `/jupyter/user/${notebookUser}/api/terminals`;
  const { response, error } = await getApiRequest<JupyterHubTerminal[]>(url);
  if (typeof response === 'string') {
    console.error('Unexpected response', response);
    return;
  }
  if (response) {
    response.forEach((terminal) => {
      void deleteApiRequest(
        `/jupyter/user/${notebookUser}/api/terminals/${terminal.name}`
      );
      thunkAPI.dispatch(
        sendNotification(
          msgsNotifications.titleWorkbenchTerminalSessionDeleted.id,
          // @ts-ignore
          msgsNotifications.titleWorkbenchTerminalSessionDeleted.id,
          EVENT_NOTIFICATION
        )
      );
    });
  }
  if (error) console.error(error);
});

export const { clearStdout } = slice.actions;
export const workbenchTerminalsReducer = slice.reducer;

import { AugurSettings } from 'common/dist/types/augurSettings';
import { AUGUR_JOBS, K8sResources } from 'common/dist/types/job';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import {
  Control,
  DeepPartialSkipArrayKey,
  FieldErrors,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReturn,
  UseFormTrigger,
  useWatch,
} from 'react-hook-form';

import {
  filterConfigForElementVisibility,
  getVisibilityDependencyArray,
} from './cfe';
import { getElements } from './config';
import { transformConfigToConfigProps } from './transformation';
import { useAugurNames } from '../../../../core/api/augurs';
import { cleanAugurResources } from '../../../atoms/react-hook-form-input-elements/resource-input/utils';
import { findElementMeta } from '../../../molecules/augur-layout-elements/common/utils';
import { toK8sResourcesSelectFormState } from '../../../molecules/k8s-resources-select/k8sResources.form';
import {
  AugurSettingsPage,
  AugurSettingsWithAugurProperties,
  isAugurSettingsElement,
  ModuleConfiguration,
  ModuleSelection,
} from '../type';

export type AugurSettingsForm = {
  general: GeneralAugurSettingsForm;
  settingsData: Record<string, unknown>;
};

export interface ResourcesForm {
  learning: K8sResources;
  evaluation: K8sResources;
  prediction: K8sResources;
  realtimePrediction: K8sResources;
}

export interface GeneralAugurSettingsForm {
  augurName: string;
  module: ModuleSelection;
  attributes: Attribute[];
  resources: ResourcesForm;
}

export type Attribute = {
  key: string;
  value: string;
};

const defaultK8sResources = {
  cpuRequest: '',
  cpuLimit: '',
  memoryRequest: '',
  memoryLimit: '',
  useGpu: false,
  gpuRequest: '',
  gpuLimit: '',
  gpuProduct: { model: '' },
} satisfies K8sResources;

/**
 * Transform an Augur Settings file to the Augur Settings form state representation.
 * Converts the keys that are used for settings data from settingsKey (file) to uuid (form).
 * @param augurSettings Augur Settings in file representation
 * @param config Module Configuration generated from EditAugur
 */
export function toAugurSettingsFormState(
  config: ModuleConfiguration,
  augurSettings?: AugurSettingsWithAugurProperties
): AugurSettingsForm {
  const settingsElements = getElements(config).filter(isAugurSettingsElement);
  return {
    general: {
      augurName: augurSettings?.general?.augurName ?? '',
      module: {
        moduleCode: augurSettings?.general?.module?.moduleCode ?? '',
        moduleVersionCode:
          augurSettings?.general?.module?.moduleVersionCode ?? '',
      },
      attributes: Object.entries(augurSettings?.general?.attributes ?? {}).map(
        ([key, value]) => ({ key, value })
      ),
      resources: {
        learning: augurSettings
          ? augurSettings?.general?.resources?.learning?.resources ??
            defaultK8sResources
          : config?.generalConfiguration?.learning?.resources,
        evaluation: augurSettings
          ? augurSettings?.general?.resources?.evaluation?.resources ??
            defaultK8sResources
          : config?.generalConfiguration?.evaluation?.resources,
        prediction: augurSettings
          ? augurSettings?.general?.resources?.prediction?.resources ??
            defaultK8sResources
          : config?.generalConfiguration?.prediction?.resources,
        realtimePrediction: augurSettings
          ? augurSettings?.general?.resources?.realtimePrediction?.resources ??
            defaultK8sResources
          : config?.generalConfiguration?.realtimePrediction?.resources,
      },
    },
    settingsData: settingsElements.reduce((acc, element) => {
      const settingsValue = augurSettings?.settingsData?.[element.settingsKey];
      return {
        ...acc,
        [element.uuid]: settingsValue ?? element.defaultAugurSettings,
      };
    }, {} as Record<string, unknown>),
  };
}

/**
 * Transform the settings form state to the actual Augur Settings file representation.
 * Converts the keys that are used for settings data from uuid (form) to settingsKey (file).
 * @param augurSettingsFormState Augur Settings form state
 * @param config Module Configuration generated from EditAugur
 */
export function fromAugurSettingsFormState(
  augurSettingsFormState: AugurSettingsForm,
  config: ModuleConfiguration
): AugurSettingsWithAugurProperties {
  const settingsElements = getElements(config).filter(isAugurSettingsElement);
  const augurSettings = settingsElements.reduce(
    (acc, element) => ({
      ...acc,
      [element.settingsKey]: augurSettingsFormState.settingsData[element.uuid],
    }),
    {} as Record<string, unknown>
  );

  const cleanedResources = cleanAugurResources(
    augurSettingsFormState.general.resources
  );

  return {
    general: {
      augurName: augurSettingsFormState.general.augurName,
      module: augurSettingsFormState.general.module,
      attributes: Object.fromEntries(
        augurSettingsFormState.general.attributes.map((entry) => [
          entry.key,
          entry.value,
        ])
      ),
      resources: {
        learning: {
          resources: cleanedResources?.learning || {},
        },
        evaluation: {
          resources: cleanedResources?.evaluation || {},
        },
        prediction: {
          resources: cleanedResources?.prediction || {},
        },
        realtimePrediction: {
          resources: cleanedResources?.realtimePrediction || {},
        },
      },
    },
    settingsData: augurSettings,
  };
}

/**
 * Takes an AugurSettings page and a form error object and extracts all element errors for the given page.
 * @param page a settings page with a list of elements
 * @param errors form errors for all settings elements
 * @returns a list of errors for the given page
 */
export function getFormPageErrors(
  page: AugurSettingsPage,
  errors: FieldErrors<AugurSettingsForm>
) {
  return page.elements
    .map((element) => errors.settingsData?.[element.uuid])
    .filter((error) => !!error);
}

/**
 * Registers all general settings fields with validation.
 * @param enabled whether this hook runs the register calls or not
 * @param register form register function
 * @param habitatCode habitatCode used for API calls
 * @param persistedAugurSettings actual values persisted in the AugurSettings file
 */
export function useRegisterGeneralSettingsFields(
  enabled: boolean,
  register: UseFormRegister<AugurSettingsForm>,
  habitatCode: string,
  persistedAugurSettings?: AugurSettingsWithAugurProperties
) {
  const {
    data: augurNames,
    isLoading,
    isError,
    error,
  } = useAugurNames(habitatCode);

  // if the hook is not enabled we don't want to register anything
  if (!enabled) return;

  register('general.augurName' as const, {
    required: { value: true, message: 'Field is required' },
    minLength: { value: 3, message: 'Min length 3' },
    maxLength: { value: 64, message: 'Max length 64' },
    validate: (value) => {
      if (value === persistedAugurSettings?.general?.augurName) {
        // value is current name
        return true;
      }

      if (isLoading) {
        return false;
      } else if (isError) {
        return JSON.stringify(error);
      } else if (Object.values(augurNames).includes(value)) {
        return 'Augur name already exists.';
      }

      return true;
    },
  });

  register('general.module.moduleCode' as const, {
    required: 'Field is required',
  });
  register('general.module.moduleVersionCode' as const, {
    required: 'Field is required',
  });
  register('general.attributes' as const, {});
}

/**
 * This basically is a trigger on change hook, that performs a deep compare to detect changes.
 * see https://github.com/react-hook-form/react-hook-form/issues/7068 for more info
 * @param control RHF control object
 * @param trigger RHF trigger function
 */
export function useValidateOnChange<T>(
  control: Control<T>,
  trigger: UseFormTrigger<T>
) {
  const [previousFormState, setPreviousFormState] =
    useState<DeepPartialSkipArrayKey<T>>();

  const formState = useWatch({
    control,
  });

  if (previousFormState && _.isEqual(previousFormState, formState)) {
    return;
  }
  setPreviousFormState(formState);
  void trigger();
}

export function useRegisterAugurSettingsFields(
  register: UseFormRegister<AugurSettingsForm>,
  moduleConfig: ModuleConfiguration
) {
  moduleConfig.augurSettingsConfiguration.forEach((page) => {
    page.elements.forEach(({ uuid, config, type, version }) => {
      try {
        const elementMeta = findElementMeta(type, version);

        register(`settingsData.${uuid}`, {
          validate: (value: unknown, formValues) => {
            const transformedConfig = transformConfigToConfigProps(
              config,
              formValues.settingsData
            );
            const validationError = elementMeta.validationFunction(
              value,
              transformedConfig
            );

            return validationError !== undefined
              ? validationError.global ??
                  'The validation inside the element has failed. Please configure the element correctly.'
              : true;
          },
        });
      } catch (e) {
        // invalid validation schema
        console.error(`Failed to register element ${uuid}:\n`, e.message);
      }
    });
  });
}

export function convertToAugurSettings<TSettingsData = unknown>(
  foundSettings: AugurSettings | undefined,
  { augurName, module, attributes }
): AugurSettingsWithAugurProperties<TSettingsData> {
  // Default empty object for settingsData
  const settingsData: Record<string, TSettingsData> = {};

  // Only process foundSettings if it's defined
  if (foundSettings) {
    // Convert AugurSettingsData to Record<string, TSettingsData>
    for (const [key, value] of Object.entries(foundSettings.settingsData)) {
      settingsData[key] = value as TSettingsData;
    }
  }

  return {
    general: {
      augurName,
      module,
      attributes,
      resources: foundSettings?.resources ?? {},
    },
    settingsData,
  };
}

/**
 * Form hook wrapper for the Augur Settings.
 *  - provides (slightly modified) form methods
 *  - registers Augur Settings fields
 *  - adds custom onChange validation
 *  - initializes
 *  - manages the current module selection
 *  - filters errors, dirty state, and submission data of invisible elements
 * @param isDevAugur flag whether the form is used for the DevAugur
 * @param initialModuleConfig the initial config that is used to initialize the form
 * @param habitatCode habitatCode of the Augur (undefined for DevAugur)
 * @param getModuleConfig function to retrieve the new ModuleConfiguration after it has been changed in the general settings
 * @param persistedAugurSettings the initial Augur Settings that have been persisted
 */
export function useAugurSettingsForm(
  isDevAugur: boolean,
  initialModuleConfig: ModuleConfiguration,
  habitatCode: string,
  getModuleConfig?: (
    moduleCode: string,
    moduleVersionCode: string
  ) => ModuleConfiguration,
  persistedAugurSettings?: AugurSettingsWithAugurProperties
): {
  formMethods: UseFormReturn<
    AugurSettingsForm,
    'AugurSettingsForm',
    AugurSettingsWithAugurProperties
  >;
  visibilityFilteredModuleConfig: ModuleConfiguration;
} {
  const [moduleVersionInfo, setModuleVersionInfo] = useState<
    ModuleSelection | undefined
  >();

  const moduleConfig = useMemo(() => {
    const { moduleCode, moduleVersionCode } =
      persistedAugurSettings?.general?.module || {};

    if (
      !isDevAugur &&
      getModuleConfig &&
      moduleVersionInfo &&
      (moduleCode !== moduleVersionInfo.moduleCode ||
        moduleVersionCode !== moduleVersionInfo.moduleVersionCode)
    ) {
      // module selection was changed -> load new ModuleConfig
      return getModuleConfig(
        moduleVersionInfo.moduleCode,
        moduleVersionInfo.moduleVersionCode
      );
    } else {
      // get the initial config if module selection wasn't changed
      return initialModuleConfig;
    }
  }, [
    initialModuleConfig,
    getModuleConfig,
    moduleVersionInfo,
    persistedAugurSettings,
    isDevAugur,
  ]);

  const defaultValues = useMemo(
    () => toAugurSettingsFormState(moduleConfig, persistedAugurSettings),
    [moduleConfig, persistedAugurSettings]
  );
  // settings form
  const settingsFormMethods = useForm<AugurSettingsForm>({
    mode: 'onSubmit',
    defaultValues: defaultValues,
  });
  const {
    register,
    unregister,
    watch,
    reset,
    setValue,
    getValues,
    formState,
    handleSubmit,
    trigger,
    control,
    resetField,
    clearErrors,
  } = settingsFormMethods;
  const { errors, dirtyFields, isSubmitSuccessful } = formState;

  // Effect to update form values when persistedAugurSettings changes
  useEffect(() => {
    if (persistedAugurSettings) {
      const newValues = toAugurSettingsFormState(
        moduleConfig,
        persistedAugurSettings
      );
      reset(newValues);
    }
  }, [persistedAugurSettings, moduleConfig, reset]);

  // reset to get rid of dirty state after submission
  // https://github.com/react-hook-form/react-hook-form/issues/3097
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(undefined, { keepValues: true });
    }
  }, [reset, isSubmitSuccessful]);

  const currentSettings = useWatch({
    name: 'settingsData',
    control,
  });

  const visibilityDependenciesLookupMap = useMemo(
    () => getVisibilityDependencyArray(moduleConfig),
    [moduleConfig]
  );
  const visibilityFilteredModuleConfig = useMemo(
    () =>
      filterConfigForElementVisibility(
        moduleConfig,
        visibilityDependenciesLookupMap,
        currentSettings
      ),
    [currentSettings, moduleConfig, visibilityDependenciesLookupMap]
  );
  const elementIds = getElements(moduleConfig).map((element) => element.uuid);
  const visibleElementIds = getElements(visibilityFilteredModuleConfig).map(
    (element) => element.uuid
  );

  // we have to unregister fields that no longer exist or are no longer visible to ensure that validation works correctly
  const [registeredFields, setRegisteredFields] = useState<string[]>([]);
  useEffect(() => {
    let isMissingIds = false;
    registeredFields.forEach((id) => {
      if (!elementIds.includes(id)) {
        // config was changed and element does not exist
        unregister(`settingsData.${id}`);
        isMissingIds = true;
      } else if (!visibleElementIds.includes(id)) {
        // visibility was changed and element is no longer visible
        unregister(`settingsData.${id}`, {
          keepValue: true,
          keepDirty: true,
          keepTouched: true,
        });
        isMissingIds = true;
      }
    });

    // only set if there is a difference
    if (isMissingIds || registeredFields.length !== visibleElementIds.length)
      setRegisteredFields(visibleElementIds);
  }, [elementIds, visibleElementIds, unregister, registeredFields]);

  // filter the error object according to the visible elements
  const filteredSettingsDataErrors = Object.fromEntries(
    Object.entries(errors.settingsData || {}).filter(([key]) =>
      visibleElementIds.includes(key)
    )
  );
  const { settingsData: settingsDataErrors, ...restErrors } = errors;
  const filteredErrors: typeof errors = {
    ...restErrors,
    ...(Object.keys(filteredSettingsDataErrors).length > 0
      ? {
          settingsData: filteredSettingsDataErrors,
        }
      : {}),
  };
  const filteredIsValid = Object.keys(filteredErrors).length === 0;

  // filter the dirty fields according to the visible elements (used for the cancel button)
  const filteredDirtyFieldsSettingsData = Object.entries(
    dirtyFields.settingsData || {}
  ).filter(([key]) => visibleElementIds.includes(key));
  const { settingsData: settingsDataDirtyFields, ...restDirtyFields } =
    dirtyFields;
  const filteredDirtyFields = {
    ...restDirtyFields,
    ...(Object.keys(filteredDirtyFieldsSettingsData).length > 0
      ? {
          settingsData: filteredDirtyFieldsSettingsData,
        }
      : {}),
  };
  const filteredIsDirty = Object.keys(filteredDirtyFields).length !== 0;

  // custom submit handler that filters the input according to element visibility
  const filteredOnSubmit = (
    data: AugurSettingsForm
  ): AugurSettingsWithAugurProperties => {
    // reset is not necessary at this point because AugurSettings get updated

    return fromAugurSettingsFormState(data, visibilityFilteredModuleConfig);
  };

  function customHandleSubmit(
    handleSubmitFn: UseFormHandleSubmit<AugurSettingsForm>
  ): UseFormHandleSubmit<AugurSettingsForm, AugurSettingsWithAugurProperties> {
    return (onValid, onInvalid) => {
      return handleSubmitFn((data) => {
        const newData = filteredOnSubmit(data);
        return onValid(newData);
      }, onInvalid);
    };
  }

  // watch for any module changes to update settings pages according to config of selected module
  const module = watch('general.module');

  useEffect(() => {
    if (!isDevAugur && module.moduleCode && module.moduleVersionCode) {
      setModuleVersionInfo(module);
    }
  }, [module, isDevAugur]);

  useEffect(() => {
    // update the settings values when the config is updated
    const currentFormState = getValues();
    // use complete config to also set default values of invisible elements
    setValue(
      'settingsData',
      toAugurSettingsFormState(
        moduleConfig,
        fromAugurSettingsFormState(currentFormState, moduleConfig)
      ).settingsData
    );
  }, [setValue, getValues, moduleConfig]);

  useRegisterGeneralSettingsFields(
    !isDevAugur,
    register,
    habitatCode,
    persistedAugurSettings
  );

  useEffect(() => {
    const configResources = toAugurSettingsFormState(
      moduleConfig,
      persistedAugurSettings
    )?.general?.resources;

    AUGUR_JOBS.forEach((field) => {
      const res = configResources[field] || {};

      const updatedResources: K8sResources = toK8sResourcesSelectFormState(res);

      setValue(`general.resources.${field}`, updatedResources, {
        shouldValidate: false,
      });
    });
    clearErrors(
      AUGUR_JOBS.map((field) => `general.resources.${field}` as const)
    );
  }, [
    module,
    moduleConfig,
    persistedAugurSettings,
    moduleVersionInfo,
    setValue,
    clearErrors,
    dirtyFields,
  ]);

  // register all elements in settingsForm to enable global validation
  useRegisterAugurSettingsFields(register, visibilityFilteredModuleConfig);

  // onChange validation trigger
  useValidateOnChange(control, trigger);

  return {
    formMethods: {
      ...settingsFormMethods,
      handleSubmit: customHandleSubmit(handleSubmit),
      formState: {
        ...formState,
        isDirty: filteredIsDirty,
        isValid: filteredIsValid,
        errors: filteredErrors,
      },
    },
    visibilityFilteredModuleConfig,
  };
}

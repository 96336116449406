import { isApiError } from 'common/dist/constants/errors';
import notificationsMsgs from 'common/dist/messages/notifications';
import { arrayToMap } from 'common/dist/utils/arrayToMap';
import {
  quartzCronToCron,
  quartzCronToTrigTimed,
} from 'common/dist/utils/schedules';
import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';

import { sendNotification } from './notifications.module';
import * as Api from '../../core/api';
import { error as errorNotification, event } from '../../core/notifications';

export const PAGE_SIZE = 10;

export const fetchSchedules = createAction(
  'fetch schedules',
  (trigger, period, offset, limit, search) => ({
    trigger,
    period,
    offset,
    limit,
    search,
  })
);

export const fetchSchedulesSuccess = createAction(
  'fetch schedules - success',
  (data) => data
);

export const fetchSchedulesError = createAction(
  'fetch schedules - error',
  (error) => error
);

export const fetchSchedule = createAction('fetch schedule', (scheduleCode) => ({
  scheduleCode,
}));

export const fetchScheduleSuccess = createAction(
  'fetch schedule - success',
  (data) => data
);

export const fetchScheduleError = createAction(
  'fetch schedule - error',
  (error) => error
);

export const deleteSchedule = createAction(
  'delete schedule',
  (scheduleCode) => ({ scheduleCode })
);

export const deleteScheduleThenFetch = createAction(
  'delete schedule then fetch schedules',
  (scheduleCode, trigger, period, offset, filter, search) => ({
    scheduleCode,
    trigger,
    period,
    offset,
    filter,
    search,
  })
);

export const showDeleteSchedule = createAction(
  'show delete schedule modal',
  (scheduleCode) => ({ scheduleCode })
);

export const hideDeleteSchedule = createAction('hide delete schedule schedule');

// --- Add Schedule component
export const showJobPicker = createAction(
  'show job picker',
  (predecessingJobCode) => ({ predecessingJobCode })
);

export const hideJobPicker = createAction('hide job picker');

export const addSchedule = createAction(
  'add schedule',
  (schedule, callback) => ({
    schedule,
    callback,
  })
);

export const addScheduleSuccess = createAction(
  'add schedule - success',
  (data) => data
);

export const addScheduleError = createAction(
  'add schedule - error',
  (error) => error
);

export const updateSchedule = createAction(
  'update schedule',
  (schedule, callback) => ({
    schedule,
    callback,
  })
);

export const updateScheduleSuccess = createAction(
  'update schedule - success',
  (data) => data
);

export const updateScheduleError = createAction(
  'update schedule - error',
  (error) => error
);

export const setActiveTab = createAction(
  'set active tab in job picker',
  (habitatCode, active) => ({ habitatCode, active })
);

export const showS3TransferJobModal = createAction(
  'show s3 transfer job modal'
);

export const hideS3TransferJobModal = createAction(
  'hide s3 transfer job modal'
);

export const reducer = {
  [fetchSchedules]: (state) => ({
    ...state,
    jobSchedules: {
      ...state.jobSchedules,
      loading: true,
    },
  }),
  [fetchSchedulesSuccess](state, data) {
    // Calculate whether the schedule is monthly, weekly, ...
    const enrichedData = data.map((schedule) => {
      const trigTimed = quartzCronToTrigTimed(schedule.trigTimed);
      const trigCron = quartzCronToCron(schedule.trigCron);
      return {
        ...schedule,

        trigTimed: trigTimed,
        trigCron: trigCron,
      };
    });

    return {
      ...state,
      jobSchedules: {
        ...state.jobSchedules,
        loading: false,
        loaded: true,
        error: '',
        codes: enrichedData.map((s) => s.scheduleCode),
        schedules: arrayToMap(enrichedData, 'scheduleCode'),
      },
    };
  },
  [fetchSchedulesError]: (state, error) => ({
    ...state,
    jobSchedules: {
      ...state.jobSchedules,
      loading: false,
      loaded: true,
      error: error || 'Schedules could not be loaded.', // TODO Use intl-id
      codes: [],
      schedules: {},
    },
  }),
  // Reuse the state for all schedules. The semantic is that we may update or create a single schedule in the state
  [fetchSchedule]: (state) => ({
    ...state,
    jobSchedules: {
      ...state.jobSchedules,
      loading: true,
    },
  }),
  [fetchScheduleSuccess](state, data) {
    // Calculate whether the schedule is monthly, weekly, ... To be consistent with the general fetch method
    let trigger = data.trigger;
    const trigCron = quartzCronToCron(data.trigCron);
    if (trigCron) {
      trigger = 'cron';
    }

    const enrichedData = {
      ...data,

      trigTimed: quartzCronToTrigTimed(data.trigTimed),
      trigCron: trigCron,
      trigger: trigger,
    };
    return {
      ...state,
      jobSchedules: {
        ...state.jobSchedules,
        loading: false,
        loaded: true,
        error: '',
        // Add the code if it doesn't exist yet
        codes: state.jobSchedules.codes.includes(data.scheduleCode)
          ? state.jobSchedules.codes
          : state.jobSchedules.codes.concat([data.scheduleCode]),
        schedules: {
          ...state.jobSchedules.schedules,
          [data.scheduleCode]: enrichedData,
        },
      },
    };
  },
  [fetchScheduleError]: (state, error) => ({
    ...state,
    jobSchedules: {
      ...state.jobSchedules,
      loading: false,
      error: error || 'Single schedule could not be loaded.', // TODO Use intl-id
    },
  }),
  [showJobPicker]: (state, { predecessingJobCode }) => ({
    ...state,
    newSchedule: {
      ...state.newSchedule,
      showJobPicker: true,
      predecessingJobCode,
    },
  }),
  [hideJobPicker]: (state) => ({
    ...state,
    newSchedule: {
      ...state.newSchedule,
      showJobPicker: false,
      predecessingJobCode: null,
    },
  }),
  [addSchedule]: (state) => ({
    ...state,
    newSchedule: {
      ...state.newSchedule,
      submitting: true,
    },
  }),
  [addScheduleSuccess]: (state) => ({
    ...state,
    newSchedule: {
      ...state.newSchedule,
      submitting: false,
    },
  }),
  [addScheduleError]: (state) => ({
    ...state,
    newSchedule: {
      ...state.newSchedule,
      submitting: false,
    },
  }),
  /** Update methods reuse the add state, since a separate state would be unneeded */
  [updateSchedule]: (state) => ({
    ...state,
    newSchedule: {
      ...state.newSchedule,
      submitting: true,
    },
  }),
  [updateScheduleSuccess]: (state) => ({
    ...state,
    newSchedule: {
      ...state.newSchedule,
      submitting: false,
    },
  }),
  [updateScheduleError]: (state) => ({
    ...state,
    newSchedule: {
      ...state.newSchedule,
      submitting: false,
    },
  }),
  [setActiveTab]: (state, { habitatCode, active }) => ({
    ...state,
    newSchedule: {
      ...state.newSchedule,
      jobPicker: {
        ...state.newSchedule.jobPicker,
        activeTabs: {
          ...state.newSchedule.jobPicker.activeTabs,
          [habitatCode]: active,
        },
      },
    },
  }),
  [showS3TransferJobModal]: (state) => ({
    ...state,
    newSchedule: {
      ...state.newSchedule,
      s3TransferModalShown: true,
    },
  }),
  [hideS3TransferJobModal]: (state) => ({
    ...state,
    newSchedule: {
      ...state.newSchedule,
      s3TransferModalShown: false,
    },
  }),
  [showDeleteSchedule]: (state, { scheduleCode }) => ({
    ...state,
    jobSchedules: {
      ...state.jobSchedules,
      deleteModal: {
        show: true,
        scheduleCode,
      },
    },
  }),
  [hideDeleteSchedule]: (state) => ({
    ...state,
    jobSchedules: {
      ...state.jobSchedules,
      deleteModal: {
        show: false,
      },
    },
  }),
};

export function* fetchSchedulesSaga({
  payload: { trigger, period, offset, limit, search },
}) {
  const { response, error } = yield call(
    Api.orchestration.fetchSchedules,
    trigger,
    period,
    offset,
    limit,
    search
  );
  if (response) {
    yield put(fetchSchedulesSuccess(response));
  } else {
    yield put(fetchSchedulesError(error));
  }
}

export function* watchFetchSchedules() {
  yield takeEvery(fetchSchedules.getType(), fetchSchedulesSaga);
}

export function* fetchScheduleSaga({ payload: { scheduleCode } }) {
  const { response, error } = yield call(
    Api.orchestration.fetchSchedule,
    scheduleCode
  );
  if (response) {
    yield put(fetchScheduleSuccess(response));
  } else {
    yield put(fetchScheduleError(error));
  }
}

export function* watchFetchSchedule() {
  yield takeEvery(fetchSchedule.getType(), fetchScheduleSaga);
}

export function* addScheduleSaga({ payload: { schedule, callback } }) {
  const { response, error } = yield call(
    Api.orchestration.addSchedule,
    schedule
  );
  if (response) {
    if (callback) callback();
    yield put(addScheduleSuccess(response));
    yield put(
      sendNotification(
        notificationsMsgs.msgTitleScheduleAddSuccess.id,
        notificationsMsgs.msgDescriptionScheduleAddSuccess.id,
        event
      )
    );
  } else {
    yield put(addScheduleError(error));
    if (isApiError(error)) {
      yield put(
        sendNotification(
          notificationsMsgs.msgTitleScheduleAddFailure.id,
          error.formattedMessage.id,
          errorNotification,
          error.formattedMessage.values
        )
      );
    } else {
      yield put(
        sendNotification(
          notificationsMsgs.msgTitleScheduleAddFailure.id,
          JSON.stringify(error),
          errorNotification
        )
      );
    }
  }
}

export function* watchAddSchedule() {
  yield takeEvery(addSchedule.getType(), addScheduleSaga);
}

export function* updateScheduleSaga({ payload: { schedule, callback } }) {
  const { response, error } = yield call(
    Api.orchestration.updateSchedule,
    schedule
  );
  if (response) {
    if (callback) callback();
    yield put(updateScheduleSuccess(response));
    yield put(
      sendNotification(
        notificationsMsgs.msgTitleScheduleUpdateSuccess.id,
        notificationsMsgs.msgDescriptionScheduleUpdateSuccess.id,
        event
      )
    );
  } else {
    yield put(updateScheduleError(error));
    if (isApiError(error)) {
      yield put(
        sendNotification(
          notificationsMsgs.msgTitleScheduleUpdateFailure.id,
          error.formattedMessage.id,
          errorNotification,
          error.formattedMessage.values
        )
      );
    } else {
      yield put(
        sendNotification(
          notificationsMsgs.msgTitleScheduleUpdateFailure.id,
          JSON.stringify(error),
          errorNotification
        )
      );
    }
  }
}

export function* watchUpdateSchedule() {
  yield takeEvery(updateSchedule.getType(), updateScheduleSaga);
}

export function* deleteScheduleSaga({
  payload: { scheduleCode, trigger, period, offset, limit, search },
}) {
  const { response, error } = yield call(
    Api.orchestration.deleteSchedule,
    scheduleCode
  );

  if (response) {
    yield put(
      fetchSchedules(
        trigger,
        period,
        // Fallbacks to make sure the fetchSchedules call can work, the limit is only set in code
        offset || 0,
        limit || PAGE_SIZE,
        search
      )
    );
    yield put(
      sendNotification(
        notificationsMsgs.msgTitleScheduleDeleteSuccess.id,
        notificationsMsgs.msgDescriptionScheduleDeleteSuccess.id,
        event
      )
    );
  } else {
    yield put(fetchSchedules(undefined, undefined, offset, limit, search));
    if (isApiError(error)) {
      yield put(
        sendNotification(
          notificationsMsgs.msgTitleScheduleDeleteFailure.id,
          error.formattedMessage.id,
          errorNotification,
          error.formattedMessage.values
        )
      );
    } else {
      yield put(
        sendNotification(
          notificationsMsgs.msgTitleScheduleDeleteFailure.id,
          JSON.stringify(error),
          errorNotification
        )
      );
    }
  }
}

export function* watchDeleteSchedule() {
  yield takeEvery(deleteScheduleThenFetch.getType(), deleteScheduleSaga);
}

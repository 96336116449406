import { augurCodeFromBucket } from 'common/dist/constants/dataManagement/s3';
import {
  S3_BUCKET_SCOPES,
  s3BucketResourceName,
  s3ResourceType,
} from 'common/dist/constants/keycloak';
import commonMessages from 'common/dist/messages/common';
import dataManagementMessages from 'common/dist/messages/dataManagement';
import { DSType } from 'common/dist/types/dataManagement/dataSource';
import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAugurNames } from '../../../../../core/api/augurs';
import { fetchDataSources } from '../../../../../redux/modules/data.dataSources.module';
import { DataSources } from '../../../../../store/dataManagement/state.types';
import { RootState, useAppDispatch } from '../../../../../store/store';
import ResourcePermissions from '../../../../organisms/permissions/ressource-permissions/ResourcePermissions.container';
import { bucketOrKeyspaceRoute, detailsRoute } from '../DataSourcePermissions';

const BucketPermissions: FC = () => {
  const { dataSourceCode, bucket } = useParams<{
    dataSourceCode: string;
    bucket: string;
  }>();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const dataSources = useSelector<RootState, DataSources>(
    (state) => state.data.dataSources
  );

  const dataSource = dataSources.data.find((ds) => ds.code === dataSourceCode);
  useEffect(() => {
    // If there are no dataSources (or they aren't an array) fetch them, but avoid it if they are already fetched.
    if (!(dataSources.data.length > 0)) {
      dispatch(fetchDataSources());
    }
  }, [dispatch, dataSources.data]);

  // find the Augur name from the bucket name
  const { data: augurNames } = useAugurNames();
  const speakingName: string | undefined =
    augurNames?.[augurCodeFromBucket(bucket).toUpperCase()];

  let detailsRows = [
    {
      key: dataManagementMessages.msgS3PermissionsRowDataSourceName,
      value: dataSource?.name,
    },
    {
      key: dataManagementMessages.msgS3PermissionsRowBucketName,
      value: bucket,
    },
  ];
  if (speakingName !== undefined) {
    detailsRows = [
      ...detailsRows,
      {
        key: dataManagementMessages.msgCassandraPermissionsRowAugurName,
        value: speakingName,
      },
    ];
  }

  return (
    <ResourcePermissions
      baseUrl={bucketOrKeyspaceRoute(DSType.S3, dataSourceCode, bucket)}
      showBackButton
      backButtonLabel={intl.formatMessage(commonMessages.backTo, {
        origin: 'Buckets',
      })}
      backToUrl={detailsRoute(DSType.S3, dataSourceCode)}
      resourceType={s3ResourceType(dataSourceCode)}
      resourceName={s3BucketResourceName(dataSourceCode, bucket)}
      details={{
        headline: dataManagementMessages.msgS3PermissionsHeadline,
        rows: detailsRows,
      }}
      scopes={S3_BUCKET_SCOPES}
      messagesGroupPermissions={{
        subTitle: dataManagementMessages.msgS3PermissionsGroupSubTitle,
        description: dataManagementMessages.msgS3PermissionsGroupDescription,
      }}
      messagesUserPermissions={{
        subTitle: dataManagementMessages.msgS3PermissionsUserSubTitle,
      }}
    />
  );
};

export default BucketPermissions;
